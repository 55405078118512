import { MENU_SCREEN_BY_BUCKET_NAME } from '@app/constants';
import { webErrorCodesDispatcher } from '@app/helpers/apiCall';
import { getPathWithOrgData } from '@app/helpers/navigation';
import { resetPathsByBreadcrumbNavigation } from '@app/helpers/restoration';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import router from '@app/router/router';
import { IBreadCrumbWeb } from '@app/types';
import {
  BASE_STORE_INITIAL_STATE,
  BUCKET_TEXT_DICTIONARY,
  BreadCrumbAction,
  CoreRootState,
  CreateBucketRequestType,
  FormMode,
  IBreadCrumb,
  IItem,
  IItemDiffObject,
  ILocationGroupItemPrices,
  IMassUpdateSelectedData,
  IMenuStore,
  IMenuType,
  IMenuTypeToSelect,
  ISpecial,
  ISpecialDiffObject,
  IUpdateMenuOnSocket,
  MASS_UPDATE_INITIAL_STATE,
  MENU_TYPE_INITIAL_STATE,
  MassPriceRequestType,
  MenuTypes,
  SectionId,
  TComboMenuTypeVersionDiff,
  TDifferences,
  TMenuTypeVersionDiff,
  TSpecialMenuTypeVersionDiff,
  actionCreatorsMenu,
  applyChangesToMenuTypes,
  applyComboChangesToMenuTypes,
  applySpecialChangesToMenuTypes,
  getMenuInitialState,
  getMenuVersionState,
  menuSelectors,
} from '@westondev/tableturn-core';
import { diff } from 'deep-diff';
import { ParseKeys } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import { NavigateFunction } from 'react-router-dom';
import i18n from '../../i18n.config';
import { setVirtualizedNavigation } from '../app/actions';
import {
  selectLocationId,
  selectOrganizationId,
} from '../selectors/appSelectors';
import { AppThunk, RootState } from '../store';
import {
  selectBreadCrumbs,
  selectBucketSelected,
  selectFinalDiffObject,
} from './menuSelectors';
import { ILoadMenuBucketChangeData } from './types';

const { setSelectedMenuMode } = actionCreatorsMenu;

const {
  setChangeData,
  setBreadCrumb,
  setBreadCrumbStatusPath,
  setChangeMassUpdateData,
} = actionCreatorsMenu;

const responsive = (value: number | string) => value as number;

export const getRequiredActionsData = (state: RootState) => {
  const organizationId = selectOrganizationId(state);
  const currentLocationId = selectLocationId(state);
  const licenseId = null;
  const licenseSettingsId = 0;
  const stationNumber = 0;

  return {
    organizationId,
    currentLocationId,
    licenseId,
    licenseSettingsId,
    stationNumber,
  };
};

const NEW_BUCKET_TEXT: Record<keyof IMenuStore, ParseKeys> = {
  menuTypes: 'components.actionButtons.addNewMenuTypeTag',
  categories: 'components.actionButtons.addNewCategoryTag',
  items: 'components.actionButtons.addNewItemTag',
  modifierItems: 'components.actionButtons.addNewModifierItemTag',
  modifierGroups: 'components.actionButtons.addNewModifierGroupTag',
  subcategories: 'components.actionButtons.addNewSubcategoryTag',
  discounts: 'components.actionButtons.addNewDiscountTag',
  combos: 'components.actionButtons.addNewComboTag',
  hotButtons: 'components.actionButtons.addNewModifierItemTag',
  specials: 'components.actionButtons.addNewSpecialTag',
  ingredients: 'components.actionButtons.addNewIngredientTag',
  itemGroups: 'components.actionButtons.addNewItemGroupTag',
  reportingGroups: 'components.actionButtons.addNewItemGroupTag',
};

const getBreadCrumbOnPressForIdByBucket = (bucket: keyof IMenuStore) => {
  const screen = MENU_SCREEN_BY_BUCKET_NAME[bucket];
  return (id: number) => () =>
    router.navigate(getPathWithOrgData(`${screen}/${id}`));
};

export const breadCrumbPopNavigationWeb = (): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const { organizationId, licenseId, currentLocationId } =
      getRequiredActionsData(state);

    const currentBucket = selectBucketSelected(state);
    const getBreadCrumbOnPressForId = currentBucket
      ? getBreadCrumbOnPressForIdByBucket(currentBucket)
      : () => () => null;

    return dispatch(
      actionCreatorsMenu.breadCrumbPopNavigation(
        () => null,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        responsive,
        getBreadCrumbOnPressForId,
      ) as AppThunk<void>,
    );
  };
};

export const initializeBreadCrumbForBucket =
  (
    bucket: keyof IMenuStore,
    id: number,
    updateName: string,
    isCreation = false,
  ): AppThunk =>
  (dispatch, getState) => {
    const breadcrumbs = selectBreadCrumbs(
      getState() as CoreRootState & RootState,
    );

    const newBreadCrumb: IBreadCrumbWeb[] = [
      {
        action: BreadCrumbAction.NAV_MAIN,
        text: BUCKET_TEXT_DICTIONARY[bucket],
        onPress: () =>
          router.navigate(
            getPathWithOrgData(MENU_SCREEN_BY_BUCKET_NAME[bucket]),
          ),
        pathURL: getPathWithOrgData(MENU_SCREEN_BY_BUCKET_NAME[bucket]),
        key: router.state.location.key,
      },
    ];

    if (!breadcrumbs.length) {
      newBreadCrumb.push({
        action: isCreation ? BreadCrumbAction.ADD : BreadCrumbAction.NAV,
        text: isCreation
          ? `${i18n.t('commonTexts.adding')} ${i18n.t(NEW_BUCKET_TEXT[bucket])}`
          : updateName,
        onPress: () =>
          router.navigate(
            getPathWithOrgData(
              `${MENU_SCREEN_BY_BUCKET_NAME[bucket]}/${
                isCreation ? 'add' : id
              }`,
            ),
          ),
        pathURL: getPathWithOrgData(
          `${MENU_SCREEN_BY_BUCKET_NAME[bucket]}/${isCreation ? 'add' : id}`,
        ),
        key: router.state.location.key,
      });

      dispatch(setBreadCrumb(newBreadCrumb));
      dispatch(setActiveStateInBreadcrumbWeb(id, bucket, 'update'));

      dispatch(
        setVirtualizedNavigation({
          breadcrumb: newBreadCrumb,
          index: newBreadCrumb.length,
          initialIndex: newBreadCrumb.length,
        }),
      );
    } else if (!id && !isCreation) {
      dispatch(
        setVirtualizedNavigation({
          breadcrumb: newBreadCrumb,
          index: breadcrumbs.length,
          initialIndex: breadcrumbs.length,
        }),
      );
    }
  };

export const initializeOtherBreadcrumbs = (): AppThunk => dispatch => {
  const newBreadCrumb: IBreadCrumbWeb[] = [
    {
      action: BreadCrumbAction.NAV_OTHER,
      text: 'Other page',
      onPress: () => router.navigate(getPathWithOrgData(location.pathname)),
      pathURL: getPathWithOrgData(location.pathname),
      key: router.state.location.key,
    },
  ];
  dispatch(
    setVirtualizedNavigation({
      breadcrumb: newBreadCrumb,
      index: newBreadCrumb.length,
      initialIndex: newBreadCrumb.length,
    }),
  );
};

export const loadMenuBucketChangeData =
  ({ bucket, id = 0, prefillData }: ILoadMenuBucketChangeData): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const mode = id ? FormMode.UPDATE : FormMode.CREATE;
    const isCreation = mode === FormMode.CREATE;

    const bucketObject = isCreation
      ? getMenuInitialState(bucket, prefillData)
      : menuSelectors.selectMenu(state as CoreRootState & RootState)[bucket][
          id
        ];

    if (!bucketObject) return;

    dispatch(
      initializeBreadCrumbForBucket(
        bucket,
        id,
        (bucketObject as IItem).name,
        isCreation,
      ),
    );

    const menuVersion =
      getMenuVersionState(state as CoreRootState & RootState) || 0;

    dispatch(
      setChangeData({
        id: id,
        bucketSelected: bucket,
        data: cloneDeep(bucketObject),
        hasChanges: false,
        differences: [],
        finalDiffObject: null,
        errors: {},
        sectionId: SectionId.ALL,
        menuVersion: menuVersion,
        selectedLocations: [],
        mode,
      }),
    );
  };

export const loadMenuTypesMenuChangeData =
  ({
    menuTypeId = 0,
    cloneId,
    assignedItemIds,
    assignedItemMenuTypeId,
    isRefreshingData,
  }: {
    menuTypeId: number;
    cloneId?: number;
    assignedItemIds?: number[];
    assignedItemMenuTypeId?: number;
    isRefreshingData?: boolean;
  }): AppThunk =>
  (dispatch, getState) => {
    const mode = menuTypeId ? FormMode.UPDATE : FormMode.CREATE;
    const isCreation = mode === FormMode.CREATE;
    const state = getState();

    const currentMenu = state.menu.selectedMode.currentMenu;
    const bucketSelected = 'menuTypes';
    const currentMenuType =
      currentMenu.menuTypes[menuTypeId || cloneId || 0] ||
      MENU_TYPE_INITIAL_STATE;

    const currentMenuTypeOverrides: Partial<IMenuType> = assignedItemIds
      ? {
          itemIds: assignedItemIds,
          categoryIds: Array.from(
            new Set(
              assignedItemIds
                .map(
                  _itemId =>
                    Object.values(
                      currentMenu.items[_itemId]?.menuTypeVersions?.[
                        assignedItemMenuTypeId || 0
                      ]?.categoriesSubcategories || 0,
                    )
                      .map(({ categoryId }) => categoryId)
                      .filter(Boolean) as number[],
                )
                .flat(),
            ),
          ),
          itemMenuTypeTemplateIds: assignedItemIds.reduce(
            (prev, current) => ({
              ...prev,
              [current]: assignedItemMenuTypeId || 0,
            }),
            {},
          ),
        }
      : {};

    const bucketObject = cloneId
      ? {
          ...currentMenuType,
          ...BASE_STORE_INITIAL_STATE,
          name: `${currentMenuType.name} (Cloned)`,
          menuTypeId: 0,
          itemMenuTypeTemplateIds: currentMenuType.itemIds.reduce(
            (prev, current) => ({ ...prev, [current]: cloneId }),
            {},
          ),
          organizationId: 0,
          locationMenuTypeId: 0,
          locationMenuTypeVersionId: 0,
        }
      : {
          ...currentMenuType,
          ...currentMenuTypeOverrides,
        };

    if ((!isRefreshingData && currentMenuType.name) || isRefreshingData)
      dispatch(
        initializeBreadCrumbForBucket(
          bucketSelected,
          menuTypeId,
          currentMenuType.name || '',
          isCreation,
        ),
      );

    const newDifferences = cloneId
      ? (diff(MENU_TYPE_INITIAL_STATE, bucketObject) as TDifferences)
      : [];

    const menuVersion =
      getMenuVersionState(state as CoreRootState & RootState) || 0;

    dispatch(
      setChangeData({
        id: menuTypeId,
        bucketSelected,
        data: cloneDeep(bucketObject),
        hasChanges: newDifferences.length > 0 ? true : false,
        differences: newDifferences,
        finalDiffObject: null,
        errors: {},
        sectionId: SectionId.ALL,
        menuVersion,
        selectedLocations: [],
        mode,
      }),
    );
  };

export const loadItemsMenuChangeData =
  ({ itemId, cloneId }: { itemId: number; cloneId?: number }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const mode = itemId ? FormMode.UPDATE : FormMode.CREATE;
    const isCreation = mode === FormMode.CREATE;
    const bucketSelected = 'items';

    if (
      !state.menu.selectedMode.currentMenu.items[itemId || cloneId || -1] &&
      !isCreation
    )
      return;

    const currentItem =
      state.menu.selectedMode.currentMenu.items[itemId || cloneId || 0] ??
      getMenuInitialState(bucketSelected);

    const bucketObject = cloneId
      ? {
          ...currentItem,
          ...BASE_STORE_INITIAL_STATE,
          name: `${currentItem.name} (Cloned)`,
          itemId: 0,
          menuTypeItemId: 0,
          organizationId: 0,
        }
      : currentItem;

    dispatch(
      initializeBreadCrumbForBucket(
        bucketSelected,
        itemId,
        bucketObject.name,
        isCreation,
      ),
    );

    const newDifferences = cloneId
      ? (diff(
          getMenuInitialState(bucketSelected),
          bucketObject,
        ) as TDifferences)
      : [];

    const menuVersion =
      getMenuVersionState(state as CoreRootState & RootState) || 0;

    dispatch(
      setChangeData({
        id: itemId,
        bucketSelected: bucketSelected,
        data: cloneDeep(bucketObject),
        hasChanges: newDifferences.length > 0 ? true : false,
        differences: newDifferences,
        finalDiffObject: null,
        errors: {},
        sectionId: SectionId.ALL,
        menuVersion,
        selectedLocations: [],
        mode,
      }),
    );
  };
export const loadMenuMassUpdateChangeData =
  ({
    massUpdateData,
    bucket,
  }: {
    massUpdateData: IMassUpdateSelectedData;
    bucket: keyof IMenuStore;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    dispatch(initializeBreadCrumbForBucket(bucket, 0, 'Mass Update', false));

    const menuVersion =
      getMenuVersionState(state as CoreRootState & RootState) || 0;

    dispatch(
      setChangeMassUpdateData({
        bucketSelected: bucket,
        data: MASS_UPDATE_INITIAL_STATE,
        originalData: MASS_UPDATE_INITIAL_STATE,
        hasChanges: false,
        sectionId: 'all',
        differences: [],
        finalDiffObject: null,
        errors: {},
        menuVersion,
        bucketIds: massUpdateData.itemsIds,
        selectionCriteria: {
          categoryIds: massUpdateData.categoryIds,
          subcategoryIds: massUpdateData.subcategoryIds,
          menuTypeIds: massUpdateData.menuTypesIds,
        },
        selectedLocations: [],
      }),
    );
  };

export const changeSelectedMenuModeWeb =
  (locationId: number, locationName: any): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const getMenuType = (id: number) => {
      const currentLocationId = state.app.locationId;
      switch (id) {
        case 0:
          return MenuTypes.MASTER_MENU;
        case currentLocationId:
          return MenuTypes.LOCATION_MENU;
        default:
          return MenuTypes.REMOTE_LOCATION_MENU;
      }
    };
    const menuType = getMenuType(locationId);

    const { locationMenu, masterMenu, versions } = getState().menu;

    switch (menuType) {
      case MenuTypes.MASTER_MENU:
        dispatch(
          setSelectedMenuMode({
            menuType: MenuTypes.MASTER_MENU,
            currentMenu: masterMenu,
            menuVersion: versions.master,
            id: 0,
            name: 'Enterprise',
          }),
        );
        break;
      case MenuTypes.LOCATION_MENU:
        dispatch(
          setSelectedMenuMode({
            menuType: MenuTypes.LOCATION_MENU,
            currentMenu: locationMenu,
            menuVersion: versions.location,
            id: locationId,
            name: locationName,
          }),
        );
        break;
      case MenuTypes.REMOTE_LOCATION_MENU:
        return;
    }
  };

export const checkForChangesAndNavigateWeb = (
  onContinue: () => void,
  onRefresh: () => void,
  onContinueResetChangeData = true,
  isSavePressed = false,
  mustCreateBeforeLeave?: keyof IMenuStore,
): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);

    const currentBucket = state.menu.changeData.bucketSelected;

    // get the navigation path for the current bucket for the last breadcrumb item added
    const getBreadCrumbOnPressForId = currentBucket
      ? getBreadCrumbOnPressForIdByBucket(currentBucket)
      : () => () => null;

    return dispatch(
      actionCreatorsMenu.checkForChangesAndNavigate(
        onContinue,
        onRefresh,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        responsive,
        getBreadCrumbOnPressForId,
        undefined,
        onContinueResetChangeData,
        isSavePressed,
        mustCreateBeforeLeave,
      ) as unknown as AppThunk<void>,
    );
  };
};

export const cancelChangesOnCancelPressWeb = (
  navigate: NavigateFunction,
): AppThunk<void> => {
  return async (dispatch, getState) => {
    const onNavigate = (bucketName: keyof IMenuStore) => {
      navigate(MENU_SCREEN_BY_BUCKET_NAME[bucketName]);
    };

    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(getState());

    return dispatch(
      actionCreatorsMenu.cancelChangesOnCancelPress(
        onNavigate,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        responsive,
        undefined,
      ) as unknown as AppThunk<void>,
    );
  };
};

export const saveMenuChangesOnSaveClickWeb = (
  onRefresh: () => void,
): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();

    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);

    const currentBucket = state.menu.changeData.bucketSelected;
    const getBreadCrumbOnPressForId = currentBucket
      ? getBreadCrumbOnPressForIdByBucket(currentBucket)
      : () => () => null;

    dispatch(
      actionCreatorsMenu.saveChangesOnSavePress(
        onRefresh,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        responsive,
        getBreadCrumbOnPressForId,
        undefined,
      ) as unknown as AppThunk<void>,
    );
  };
};

export const checkForFreshSheetChangesAndNavigateWeb = (
  onContinue: () => void,
  onContinueResetChangeData = true,
): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();

    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);

    return dispatch(
      actionCreatorsMenu.checkForFreshSheetChangesAndNavigate(
        onContinue,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        responsive,
        undefined,
        onContinueResetChangeData,
      ) as unknown as AppThunk<void>,
    );
  };
};

export const createBucketElementWeb =
  (
    onRefresh: () => void,
    request?: CreateBucketRequestType,
    mode?: FormMode,
  ): AppThunk<Promise<{ success: boolean; id: number }>> =>
  async (dispatch, getState) => {
    const state = getState();

    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);

    const currentBucket =
      request?.bucketSelected || state.menu.changeData.bucketSelected;
    const getBreadCrumbOnPressForId = currentBucket
      ? getBreadCrumbOnPressForIdByBucket(currentBucket)
      : () => () => null;

    return dispatch(
      actionCreatorsMenu.createBucketElement(
        onRefresh,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        responsive,
        getBreadCrumbOnPressForId,
        undefined,
        request,
        mode,
      ) as unknown as AppThunk<Promise<{ success: boolean; id: number }>>,
    );
  };

export const deleteBucketElementWeb =
  (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
    const state = getState();

    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);

    return dispatch(
      actionCreatorsMenu.deleteBucketElement(
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        responsive,
        undefined,
      ) as unknown as AppThunk<Promise<boolean>>,
    );
  };

export const assignItemsToLocationWeb =
  (
    ids: number[],
    bucket: keyof IMenuStore,
    locationId?: number,
  ): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();

    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);

    return dispatch(
      actionCreatorsMenu.assignItemsToLocation(
        ids,
        bucket,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        undefined,
        locationId,
      ) as unknown as AppThunk<void>,
    );
  };

export const applySpecialChangesToMenuTypesWeb =
  (
    appliedChanges: {
      currentMenuType: number;
      menuTypesToApplyChanges: IMenuTypeToSelect[];
    },
    onContinue: (data: {
      newMenuTypeVersions: TSpecialMenuTypeVersionDiff[] | undefined;
      newData: ISpecial;
    }) => void,
  ): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();

    const formattedDifferences = selectFinalDiffObject(
      state,
    ) as ISpecialDiffObject;

    const { newMenuTypeVersions, newData } = applySpecialChangesToMenuTypes(
      formattedDifferences.menuTypeVersions,
      {
        ...appliedChanges,
        currentMenuType:
          formattedDifferences.menuTypeVersions[appliedChanges.currentMenuType]
            .id,
      },
      state,
      dispatch,
    );
    onContinue({ newMenuTypeVersions, newData });
  };
export const applyChangesToMenuTypesWeb =
  (
    appliedChanges: {
      currentMenuType: number;
      menuTypesToApplyChanges: IMenuTypeToSelect[];
    },
    onContinue: (data: {
      newMenuTypeVersions:
        | TMenuTypeVersionDiff[]
        | TComboMenuTypeVersionDiff[]
        | undefined;
    }) => void,
  ): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();

    const formattedDifferences = selectFinalDiffObject(
      state,
    ) as IItemDiffObject;

    const bucket = selectBucketSelected(state);

    const action = (
      bucket === 'items'
        ? applyChangesToMenuTypes
        : applyComboChangesToMenuTypes
    ) as typeof applyChangesToMenuTypes;

    const newMenuTypeVersions = action(
      formattedDifferences.menuTypeVersions,
      {
        ...appliedChanges,
        currentMenuType:
          formattedDifferences.menuTypeVersions[appliedChanges.currentMenuType]
            .id,
      },
      state,
      dispatch,
    );
    onContinue({ newMenuTypeVersions });
  };

export const getItemPriceListByIdAndMenuTypeIdWeb =
  (
    itemId: number,
    menuTypeId: number,
    locationIds: number[],
    isCombo = false,
  ): AppThunk<Promise<ILocationGroupItemPrices>> =>
  async (dispatch, getState) => {
    const state = getState();
    const { organizationId, currentLocationId, licenseId, licenseSettingsId } =
      getRequiredActionsData(state);

    return dispatch(
      actionCreatorsMenu.getItemPriceListByIdAndMenuTypeId(
        itemId,
        menuTypeId,
        locationIds,
        organizationId,
        currentLocationId,
        licenseId,
        licenseSettingsId,
        webErrorCodesDispatcher,
        undefined,
        isCombo,
      ) as AppThunk<Promise<ILocationGroupItemPrices>>,
    );
  };

export const massPriceUpdateWeb =
  (request: MassPriceRequestType, isCombo = false): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();

    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);

    return dispatch(
      actionCreatorsMenu.massPriceUpdate(
        request,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        undefined,
        isCombo,
      ) as AppThunk<void>,
    );
  };

export const itemMassUpdateConfirmationWeb = (
  itemsCount: number,
  massUpdateData: IMassUpdateSelectedData,
  onSubmit: () => void,
  updateChangeData?: boolean,
): AppThunk<void> => {
  return async dispatch => {
    const onNavigate = () => {
      const encodedMassUpdateData = encodeURIComponent(
        JSON.stringify(massUpdateData),
      );
      router.navigate(
        `${getPathWithOrgData(
          '/menu/items/mass-update',
        )}?mass_update_data=${encodedMassUpdateData}`,
        {
          state: { massUpdateData },
        },
      );
    };

    return dispatch(
      actionCreatorsMenu.itemMassUpdateConfirmation(
        itemsCount,
        massUpdateData,
        onSubmit,
        onNavigate,
        updateChangeData,
      ) as AppThunk<void>,
    );
  };
};

export const checkBreadCrumbLocationWeb =
  (locationIndex: number, onRefresh: () => void): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);

    const currentBucket = state.menu.changeData.bucketSelected;
    const breadCrumbs = selectBreadCrumbs(getState()) as IBreadCrumbWeb[];
    const getBreadCrumbOnPressForId = currentBucket
      ? getBreadCrumbOnPressForIdByBucket(currentBucket)
      : () => () => null;

    dispatch(
      checkForChangesAndNavigateWeb(() => {
        // Ask For changes and navigate back the amount of pages needed
        const pagesToNavigateBack = breadCrumbs.length - (locationIndex + 1);
        router.navigate(-pagesToNavigateBack);

        window.history.replaceState(
          null,
          '',
          breadCrumbs[locationIndex].pathURL,
        );
        router.navigate('#', { state: { blockNext: true } });

        // Continue breadcrumb location check
        dispatch(
          actionCreatorsMenu.checkBreadCrumbLocation(
            locationIndex,
            onRefresh,
            organizationId,
            currentLocationId,
            licenseId,
            webErrorCodesDispatcher,
            responsive,
            getBreadCrumbOnPressForId,
            undefined,
            true,
          ) as unknown as AppThunk<void>,
        );

        // Remove all paths after the clicked breadcrumb and reset breadcrumb
        const removedBreadcrumbs = breadCrumbs.slice(
          locationIndex + 1,
          breadCrumbs.length,
        ) as IBreadCrumbWeb[];

        resetPathsByBreadcrumbNavigation(removedBreadcrumbs, locationIndex);
      }, useRefreshMenuWeb),
    );
  };

export const addBreadcrumbLocationWeb = (
  breadCrumb: IBreadCrumb & { pathURL?: string },
): AppThunk<void> => {
  return async dispatch => {
    breadCrumb = {
      ...breadCrumb,
      pathURL: breadCrumb.pathURL ? getPathWithOrgData(breadCrumb.pathURL) : '',
    };
    return dispatch(
      actionCreatorsMenu.addBreadCrumbLocation(
        breadCrumb,
      ) as unknown as AppThunk<void>,
    );
  };
};

export const updateMenuOnSocketWeb =
  (payload: IUpdateMenuOnSocket): AppThunk<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);
    dispatch(
      actionCreatorsMenu.updateMenuOnSocket(
        payload,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        undefined,
        false,
      ),
    );
  };

export const setActiveStateInBreadcrumbWeb = (
  id: number,
  bucket: keyof IMenuStore,
  mode: 'update' | 'reset',
): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const breadcrumb = selectBreadCrumbs(state);
    const currentBreadCrumbIndex = breadcrumb.length - 1;

    if (!currentBreadCrumbIndex || !breadcrumb.length) return;
    if (mode === 'update') {
      dispatch(
        setBreadCrumbStatusPath(
          currentBreadCrumbIndex,
          'changeData.data.active',
        ),
      );
    } else {
      dispatch(
        setBreadCrumbStatusPath(
          currentBreadCrumbIndex,
          `selectedMode.currentMenu.${bucket}.${id}.active`,
        ),
      );
    }
  };
};
