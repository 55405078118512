import Card from '@app/components/common/Card';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  selectChangeMassUpdateData,
  selectItems,
  selectReportingGroups,
} from '@app/state/menu/menuSelectors';
import {
  actionCreatorsMenu,
  FormMode,
  IMassUpdate,
} from '@westondev/tableturn-core';
import without from 'lodash/without';
import { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IItemReportingGroupsSubsection } from './types';

const ItemReportingGroupsSubsection = ({
  t,
  menuTypeVersionId,
  itemData,
  sectionId,
  mode,
  reportingGroupsField = 'reportingGroupIds',
}: IItemReportingGroupsSubsection) => {
  const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const { updateItem: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const isMassUpdate = mode === FormMode.MASS_UPDATE;

  const isRemoveReportingGroupIds =
    reportingGroupsField === 'removeReportingGroupIds';

  const reportingGroups = useSelector(selectReportingGroups);
  const items = useSelector(selectItems);
  const allReportingGroupIds = useRootSelector(
    state => Object.keys(selectReportingGroups(state)).map(Number),
    shallowEqual,
  );

  const allItemIds = useRootSelector(
    state => selectChangeMassUpdateData(state)?.bucketIds || [],
    shallowEqual,
  );

  const allItemReportingGroupIds = useMemo(() => {
    const newArray: number[] = [];
    allItemIds.forEach(id => {
      const item = items[id];
      if (item) {
        newArray.push(...item.reportingGroupIds);
      }
    });
    return Array.from(new Set(newArray));
  }, [allItemIds, items]);

  const allowedReportingGroupIds = useMemo(
    () =>
      (isRemoveReportingGroupIds
        ? allItemReportingGroupIds
        : allReportingGroupIds) || [],
    [allReportingGroupIds, isRemoveReportingGroupIds, allItemReportingGroupIds],
  );

  const generateCustomCards = useCallback(
    (reportingGroupIds: number[]) => {
      const cards = reportingGroupIds.map(id => ({
        id: id,
        title: reportingGroups[id].name,
        isActive: true,
        onRemoveClick: (reportingGroupId: number | string) => {
          const newItemReportingGroups = without(
            (itemData as IMassUpdate)[reportingGroupsField] as number[],
            Number(reportingGroupId),
          );

          setValue(
            {
              [reportingGroupsField]: newItemReportingGroups,
            },
            sectionId,
            menuTypeVersionId,
          );
        },
        onClick: () => null,
      }));

      return cards;
    },
    [
      reportingGroups,
      itemData,
      menuTypeVersionId,
      sectionId,
      setValue,
      reportingGroupsField,
    ],
  );

  const itemReportingGroupCards = useMemo(() => {
    const reportingGroupIds =
      ((itemData as IMassUpdate)?.[reportingGroupsField] as number[]) || [];

    const cards = generateCustomCards(reportingGroupIds);

    return cards;
  }, [itemData, generateCustomCards, reportingGroupsField]);

  if (!(itemData as IMassUpdate)[reportingGroupsField]) {
    return <></>;
  }

  const handleOnAssociate = (newButtons: IItemSelection[]) => {
    const newButtonIds = newButtons.map(button => Number(button.id));

    const newReportingGroups = [
      ...((itemData as IMassUpdate)[reportingGroupsField] || []),
      ...newButtonIds,
    ];

    setValue(
      { [reportingGroupsField]: newReportingGroups },
      sectionId,
      menuTypeVersionId,
    );
    setIsSelectionModalOpen(false);
  };

  const sectionContainerProps = isMassUpdate
    ? {
        csx: { borderWidth: 0 },
        cardsContainerCsx: { padding: 0 },
      }
    : {};

  const translationPath = isRemoveReportingGroupIds ? 'remove' : 'add';

  return (
    <>
      <SelectionModal
        type="reportingGroups"
        detailsScreenProps={{
          wantedEntity: 'reportingGroups',
          currentRelationsIds: (itemData as IMassUpdate)[
            reportingGroupsField
          ].map(String),
          filterFunction: reportingGroupToFilter => {
            const allowedReportingGroupStringIds =
              allowedReportingGroupIds.map(String);
            return reportingGroupToFilter.filter(reportingGroup =>
              allowedReportingGroupStringIds.includes(reportingGroup),
            );
          },
        }}
        showDescriptionPanel={false}
        showSelectedButtons
        scrollCardSectionNumberOfColumns={5}
        active={isSelectionModalOpen}
        onModalClose={() => setIsSelectionModalOpen(false)}
        onAssociate={handleOnAssociate}
      />
      <AssignedElements
        elements={itemReportingGroupCards}
        subCardProps={{
          title: t(
            isMassUpdate
              ? `menuScreen.itemDetails.reportingSection.${translationPath}ReportingSection.title`
              : 'menuScreen.itemDetails.reportingSection.title',
          ),
          subtitle: isMassUpdate
            ? t(
                `menuScreen.itemDetails.reportingSection.${translationPath}ReportingSection.description`,
              )
            : undefined,
          actionOptions: [
            {
              text: t(
                'menuScreen.menuLocation.actionButtons.addNewReportingGroup',
              ),
              icon: false,
              handler: () => setIsSelectionModalOpen(true),
            },
          ],
          onEditMode: () => setIsEditMode(!isEditMode),
          ...sectionContainerProps,
        }}
        noElementsMessage={t(
          isMassUpdate
            ? `menuScreen.itemDetails.reportingSection.${translationPath}ReportingSection.empty`
            : 'menuScreen.itemDetails.reportingSection.emptyCardsMessage',
        )}
        renderItem={card => (
          <Card.Item
            key={`itemReportingGroupCard${card.id}`}
            showRemoveIcon={isEditMode}
            {...card}
          />
        )}
      />
    </>
  );
};

export default ItemReportingGroupsSubsection;
