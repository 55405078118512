import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Switch from '@app/components/common/Switch';
import Typography from '@app/components/common/Typography';
import { store } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BEHAVIOR_ON_NEW_ORDER_OPTIONS,
  IRoleSettingsStore,
  NavigationActionOnPrintCheck,
  NavigationActionOnSendType,
  QSR_ACTION_ON_PAY_OPTIONS,
  RoleDefaultModuleType,
  SettingsSectionId,
  actionCreatorsSettings,
  behaviorOnNewOrderFactory,
  registerModeQsrActionOnPayOptionsFactory,
  settingsSelectors,
  DEFAULT_ORDER_TYPE_OPTIONS,
  registerModeDefaultOrderTypeFactory,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const { makeSelectSettingsData } = settingsSelectors;

const NAVIGATION_ACTION_OPTIONS: Record<number, NavigationActionOnSendType> = {
  1: NavigationActionOnSendType.VIEW_TABLES,
  2: NavigationActionOnSendType.VIEW_TABS,
  3: NavigationActionOnSendType.VIEW_ORDERS,
  4: NavigationActionOnSendType.NEW_ORDER,
  5: NavigationActionOnSendType.LOGOUT,
  6: NavigationActionOnSendType.STAY_IN_ORDER,
};

const NAVIGATION_ACTION_OPTIONS_WITH_SEND: Record<
  number,
  NavigationActionOnPrintCheck
> = {
  1: NavigationActionOnPrintCheck.SEND_AND_VIEW_TABLES,
  2: NavigationActionOnPrintCheck.SEND_AND_VIEW_TABS,
  3: NavigationActionOnPrintCheck.SEND_AND_VIEW_ORDERS,
  4: NavigationActionOnPrintCheck.SEND_AND_CLEAR_REGISTER,
  5: NavigationActionOnPrintCheck.SEND_AND_LOGOUT,
  6: NavigationActionOnPrintCheck.SEND,
  7: NavigationActionOnPrintCheck.NONE,
};

const DEFAULT_REGISTER_SCREEN: NavigationActionOnSendType[] = [
  NavigationActionOnSendType.VIEW_TABLES,
  NavigationActionOnSendType.VIEW_TABS,
  NavigationActionOnSendType.VIEW_ORDERS,
  NavigationActionOnSendType.NEW_ORDER,
];

const SECTION_ID = SettingsSectionId.REGISTER;
const getMemoizedRoleData =
  makeSelectSettingsData<IRoleSettingsStore>(SECTION_ID);

const RegisterSettings = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );
  const { data } = useSelector(getMemoizedRoleData);

  const activeSwitch = (
    <Switch
      label={t('settingsModule.roleDetailsSettings.allowAccessToThisModule')}
      checked={
        data ? data.homeScreenPermissions.registerModuleAvailable : false
      }
      onChange={isActive => {
        const currentData = store.getState().settings.changeData
          .data as IRoleSettingsStore;

        const currentDefaultModule = currentData.defaultModule;

        const newDefaultModule =
          !isActive &&
          currentDefaultModule === RoleDefaultModuleType.REGISTER_MODULE
            ? RoleDefaultModuleType.HOME_MODULE
            : currentDefaultModule;

        const newHomeScreenPermissions = {
          ...currentData?.homeScreenPermissions,
          registerModuleAvailable: isActive,
        };

        setValue<IRoleSettingsStore>(
          {
            homeScreenPermissions: newHomeScreenPermissions,
            defaultModule: newDefaultModule,
          },
          SECTION_ID,
        );

        if (
          currentData?.homeScreenPermissions.registerModuleDefault &&
          !isActive
        ) {
          setValue<IRoleSettingsStore>(
            {
              homeScreenPermissions: {
                ...newHomeScreenPermissions,
                registerModuleDefault: false,
              },
            },
            SettingsSectionId.HOME,
          );
        } else {
          setValue<IRoleSettingsStore>({}, SettingsSectionId.HOME);
        }
      }}
    />
  );

  const behaviorDropdownOptions = useMemo(
    () => behaviorOnNewOrderFactory(t),
    [t],
  );

  const behaviorOnNewOrderValue = useMemo(
    () =>
      Number(
        Object.keys(BEHAVIOR_ON_NEW_ORDER_OPTIONS).find(
          key =>
            BEHAVIOR_ON_NEW_ORDER_OPTIONS[Number(key)] ===
            data?.registerPermissions.behaviorOnNewOrder,
        ),
      ),
    [data?.registerPermissions.behaviorOnNewOrder],
  );

  const qsrActionOnPayOptions = useMemo(
    () => registerModeQsrActionOnPayOptionsFactory(t).reverse(),
    [t],
  );

  const defaultRegisterScreenValue = useMemo(() => {
    if (data?.registerPermissions.defaultRegisterScreen) {
      const index = DEFAULT_REGISTER_SCREEN.indexOf(
        data.registerPermissions.defaultRegisterScreen,
      );
      if (index === -1) return undefined;
      return index + 1;
    }
    return undefined;
  }, [data?.registerPermissions.defaultRegisterScreen]);

  const navigationActionOnCloseOrderValue = useMemo(() => {
    const index = Object.keys(NAVIGATION_ACTION_OPTIONS).find(
      key =>
        NAVIGATION_ACTION_OPTIONS[Number(key)] ===
        data?.registerPermissions.navigationActionOnCloseOrder,
    );
    return index ? Number(index) : undefined;
  }, [data?.registerPermissions.navigationActionOnCloseOrder]);

  const navigationActionOnTipPendingValue = useMemo(() => {
    const index = Object.keys(NAVIGATION_ACTION_OPTIONS).find(
      key =>
        NAVIGATION_ACTION_OPTIONS[Number(key)] ===
        data?.registerPermissions.navigationActionOnTipPending,
    );
    return index ? Number(index) : undefined;
  }, [data?.registerPermissions.navigationActionOnTipPending]);

  const navigationActionDropdownValue = useMemo(
    () =>
      Number(
        Object.keys(NAVIGATION_ACTION_OPTIONS).find(
          key =>
            NAVIGATION_ACTION_OPTIONS[Number(key)] ===
            data?.registerPermissions.navigationActionOnSendButton,
        ),
      ),
    [data?.registerPermissions.navigationActionOnSendButton],
  );

  const navigationStayActionDropdownValue = useMemo(
    () =>
      Number(
        Object.keys(NAVIGATION_ACTION_OPTIONS).find(
          key =>
            NAVIGATION_ACTION_OPTIONS[Number(key)] ===
            data?.registerPermissions.navigationActionOnSendStayButton,
        ),
      ),
    [data?.registerPermissions.navigationActionOnSendStayButton],
  );

  const qsrActionOnPayValue = useMemo(
    () =>
      QSR_ACTION_ON_PAY_OPTIONS.indexOf(
        data?.registerPermissions.qsrActionOnPay,
      ) + 1,
    [data?.registerPermissions.qsrActionOnPay],
  );

  const defaultRegisterScreenDropdownValues = useMemo(() => {
    return [
      {
        label: t(
          'settingsModule.roleDetailsSettings.register.navigationOnAction.defaultRegisterScreen.options.viewTables',
        ),
        value: 1,
      },
      {
        label: t(
          'settingsModule.roleDetailsSettings.register.navigationOnAction.defaultRegisterScreen.options.viewTabs',
        ),
        value: 2,
      },
      {
        label: t(
          'settingsModule.roleDetailsSettings.register.navigationOnAction.defaultRegisterScreen.options.viewOrders',
        ),
        value: 3,
      },
      {
        label: t(
          'settingsModule.roleDetailsSettings.register.navigationOnAction.defaultRegisterScreen.options.newOrder',
        ),
        value: 4,
      },
    ];
  }, [t]);

  const navigationTSRActionDropdownValues = useMemo(() => {
    return [
      ...defaultRegisterScreenDropdownValues,
      {
        label: t(
          'settingsModule.roleDetailsSettings.register.navigationOnAction.qsrActionOnPay.options.logout',
        ),
        value: 5,
      },
    ];
  }, [defaultRegisterScreenDropdownValues, t]);

  const navigationStayTSRActionDropdownValues = useMemo(() => {
    return [
      {
        label: t(
          'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnSendStayButton.options.stayInOrder',
        ),
        value: 6,
      },
      ...navigationTSRActionDropdownValues,
    ];
  }, [navigationTSRActionDropdownValues, t]);

  const defaultOrderTypeOptions = useMemo(
    () => registerModeDefaultOrderTypeFactory(t).reverse(),
    [t],
  );
  const defaultOrderTypeValue = useMemo(
    () =>
      DEFAULT_ORDER_TYPE_OPTIONS.indexOf(
        data.registerPermissions.defaultOrderType,
      ) + 1,
    [data.registerPermissions.defaultOrderType],
  );

  const navigationActionOnPrintChecksOptions = useMemo(() => {
    return [
      {
        label: t(
          'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnPrintChecks.options.sendAndViewTables',
        ),
        value: 1,
      },
      {
        label: t(
          'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnPrintChecks.options.sendAndViewTabs',
        ),
        value: 2,
      },
      {
        label: t(
          'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnPrintChecks.options.sendAndViewOrders',
        ),
        value: 3,
      },
      {
        label: t(
          'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnPrintChecks.options.sendAndClearRegister',
        ),
        value: 4,
      },
      {
        label: t(
          'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnPrintChecks.options.sendAndLogout',
        ),
        value: 5,
      },
      {
        label: t(
          'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnPrintChecks.options.send',
        ),
        value: 6,
      },
      {
        label: t(
          'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnPrintChecks.options.none',
        ),
        value: 7,
      },
    ];
  }, [t]);

  const navigationActionOnPrintChecksValue = useMemo(
    () =>
      Number(
        Object.keys(NAVIGATION_ACTION_OPTIONS_WITH_SEND).find(
          key =>
            NAVIGATION_ACTION_OPTIONS_WITH_SEND[Number(key)] ===
            data?.registerPermissions.navigationActionOnPrintChecks,
        ),
      ),
    [data?.registerPermissions.navigationActionOnPrintChecks],
  );

  const navigationActionOnFireValue = useMemo(
    () =>
      Number(
        Object.keys(NAVIGATION_ACTION_OPTIONS_WITH_SEND).find(
          key =>
            NAVIGATION_ACTION_OPTIONS_WITH_SEND[Number(key)] ===
            data?.registerPermissions.navigationActionOnFire,
        ),
      ),
    [data?.registerPermissions.navigationActionOnFire],
  );

  return (
    <AccordionSection
      title={t('loggedIn.commonTitles.register')}
      expandedHeight="70px"
      description={t('settingsModule.roleDetailsSettings.register.description')}
      titleTextContainerCsx={{ minWidth: '150px' }}
      collapsedHeaderComponent={
        <Box
          csx={[
            {
              display: 'flex',
              height: '100%',
              gap: '10px',
            },
          ]}>
          <Divider direction="vertical" />
          {activeSwitch}
        </Box>
      }
      {...props}>
      <>
        {activeSwitch}
        <Divider csx={{ marginBlock: '20px' }} />
        <Grid columnGap={20} rowGap={10}>
          <Grid.Item>
            <DetailsSubtitle>
              {t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.title',
              )}
            </DetailsSubtitle>
          </Grid.Item>
          <Grid.Item lg={3}>
            <Dropdown
              label={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.defaultRegisterScreen.label',
              )}
              placeholder={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.defaultRegisterScreen.label',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.defaultRegisterScreen.toolTip',
              )}
              data={[defaultRegisterScreenDropdownValues]}
              onChange={defaultScreen => {
                const newValue = DEFAULT_REGISTER_SCREEN[defaultScreen - 1];

                setValue(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      defaultRegisterScreen: newValue,
                    },
                  },
                  SECTION_ID,
                );
              }}
              value={defaultRegisterScreenValue}
            />
          </Grid.Item>

          <Grid.Item lg={3}>
            <Dropdown
              label={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnSendButton.label',
              )}
              placeholder={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnSendButton.label',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnSendButton.toolTip',
              )}
              data={[navigationTSRActionDropdownValues]}
              onChange={actionScreen => {
                const newValue =
                  actionScreen in NAVIGATION_ACTION_OPTIONS
                    ? NAVIGATION_ACTION_OPTIONS[actionScreen]
                    : undefined;
                setValue(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      navigationActionOnSendButton: newValue,
                    },
                  },
                  SECTION_ID,
                );
              }}
              value={navigationActionDropdownValue}
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Dropdown
              label={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnSendStayButton.label',
              )}
              placeholder={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnSendStayButton.label',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnSendStayButton.toolTip',
              )}
              data={[navigationStayTSRActionDropdownValues]}
              onChange={actionScreen => {
                const newValue =
                  actionScreen in NAVIGATION_ACTION_OPTIONS
                    ? NAVIGATION_ACTION_OPTIONS[actionScreen]
                    : undefined;
                setValue(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      navigationActionOnSendStayButton: newValue,
                    },
                  },
                  SECTION_ID,
                );
              }}
              value={navigationStayActionDropdownValue}
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Dropdown
              label={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnCloseOrder.label',
              )}
              placeholder={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnCloseOrder.label',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnCloseOrder.toolTip',
              )}
              data={[navigationTSRActionDropdownValues]}
              onChange={actionScreen => {
                const newValue =
                  actionScreen in NAVIGATION_ACTION_OPTIONS
                    ? NAVIGATION_ACTION_OPTIONS[actionScreen]
                    : undefined;
                setValue(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      navigationActionOnCloseOrder: newValue,
                    },
                  },
                  SECTION_ID,
                );
              }}
              value={navigationActionOnCloseOrderValue}
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Dropdown
              label={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnTipPending.label',
              )}
              placeholder={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnTipPending.label',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnTipPending.toolTip',
              )}
              data={[navigationStayTSRActionDropdownValues]}
              onChange={actionScreen => {
                const newValue =
                  actionScreen in NAVIGATION_ACTION_OPTIONS
                    ? NAVIGATION_ACTION_OPTIONS[actionScreen]
                    : undefined;
                setValue(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      navigationActionOnTipPending: newValue,
                    },
                  },
                  SECTION_ID,
                );
              }}
              value={navigationActionOnTipPendingValue}
            />
          </Grid.Item>

          <Grid.Item lg={3}>
            <Dropdown
              label={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.qsrActionOnPay.label',
              )}
              placeholder={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.qsrActionOnPay.label',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.qsrActionOnPay.toolTip',
              )}
              data={[qsrActionOnPayOptions]}
              onChange={qsrActionOnPay => {
                setValue(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,

                      qsrActionOnPay:
                        QSR_ACTION_ON_PAY_OPTIONS[qsrActionOnPay - 1],
                    },
                  },
                  SECTION_ID,
                );
              }}
              value={qsrActionOnPayValue}
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Dropdown
              label={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnPrintChecks.label',
              )}
              placeholder={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnPrintChecks.label',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnPrintChecks.toolTip',
              )}
              data={[navigationActionOnPrintChecksOptions]}
              onChange={navigationActionOnPrintChecks => {
                setValue(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,

                      navigationActionOnPrintChecks:
                        NAVIGATION_ACTION_OPTIONS_WITH_SEND[
                          navigationActionOnPrintChecks
                        ],
                    },
                  },
                  SECTION_ID,
                );
              }}
              value={navigationActionOnPrintChecksValue}
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Dropdown
              label={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnFire.label',
              )}
              placeholder={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnFire.label',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.navigationActionOnFire.toolTip',
              )}
              data={[navigationActionOnPrintChecksOptions]}
              onChange={navigationActionOnFire => {
                setValue(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,

                      navigationActionOnFire:
                        NAVIGATION_ACTION_OPTIONS_WITH_SEND[
                          navigationActionOnFire
                        ],
                    },
                  },
                  SECTION_ID,
                );
              }}
              value={navigationActionOnFireValue}
            />
          </Grid.Item>

          <Grid.Item lg={3}>
            <Dropdown
              label={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.defaultOrderType.label',
              )}
              placeholder={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.defaultOrderType.label',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.navigationOnAction.defaultOrderType.toolTip',
              )}
              data={[defaultOrderTypeOptions]}
              onChange={defaultOrderType => {
                setValue(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,

                      defaultOrderType:
                        DEFAULT_ORDER_TYPE_OPTIONS[defaultOrderType - 1],
                    },
                  },
                  SECTION_ID,
                );
              }}
              value={defaultOrderTypeValue}
            />
          </Grid.Item>

          <Grid.Item>
            <Box
              csx={{
                width: 'inherit',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                marginTop: '20px',
              }}>
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.navigationOnAction.autoFilterTableTabsOrders.label',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.navigationOnAction.autoFilterTableTabsOrders.toolTip',
                )}
                checked={
                  data
                    ? data.registerPermissions.autoFilterTableTabsOrders
                    : false
                }
                onChange={autoFilterTableTabsOrders =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        autoFilterTableTabsOrders,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
            </Box>
          </Grid.Item>

          <Grid.Item>
            <Divider csx={{ marginBlock: '20px' }} />

            <DetailsSubtitle>
              {t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.title',
              )}
            </DetailsSubtitle>
            <Typography variant="caption">
              {t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.description',
              )}
            </Typography>
          </Grid.Item>
          <Grid.Item
            sm={6}
            md={6}
            lg={6}
            xl={3}
            csx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '15px',
            }}>
            <Box
              csx={{
                width: 'inherit',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}>
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.createOrders',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.createOrderstoolTip',
                )}
                checked={data ? data.registerPermissions.createOrders : false}
                onChange={createOrders =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        createOrders,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
              <Dropdown
                label={t(
                  'settingsModule.roleDetailsSettings.register.behaviorOnNewOrder.label',
                )}
                placeholder={t(
                  'settingsModule.roleDetailsSettings.register.behaviorOnNewOrder.label',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.behaviorOnNewOrder.toolTip',
                )}
                data={[behaviorDropdownOptions]}
                onChange={defaultModule => {
                  const newValue = BEHAVIOR_ON_NEW_ORDER_OPTIONS[defaultModule];

                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        behaviorOnNewOrder: newValue,
                      },
                    },
                    SECTION_ID,
                  );
                }}
                value={behaviorOnNewOrderValue}
              />
              <Divider />
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.deleteOrders',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.deleteOrderstoolTip',
                )}
                checked={data ? data.registerPermissions.deleteOrders : false}
                onChange={deleteOrders =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        deleteOrders,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.voidIndividualItems',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.voidIndividualItemstoolTip',
                )}
                checked={
                  data ? !!data.registerPermissions.voidIndividualItems : false
                }
                onChange={voidIndividualItems =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        voidIndividualItems,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
            </Box>
            <Divider direction="vertical" />
          </Grid.Item>
          <Grid.Item
            sm={6}
            md={6}
            lg={6}
            xl={3}
            csx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '15px',
            }}>
            <Box
              csx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '25px',
              }}>
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.manageTables',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.manageTablestoolTip',
                )}
                checked={data ? data.registerPermissions.manageTables : false}
                onChange={manageTables =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        manageTables,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.allowChangeMenuType',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.allowChangeMenuTypetoolTip',
                )}
                checked={
                  data ? data.registerPermissions.showMenuSelector : false
                }
                onChange={showMenuSelector =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        showMenuSelector,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.editItemPrice',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.editItemPricetoolTip',
                )}
                checked={data ? data.registerPermissions.editItemPrice : false}
                onChange={editItemPrice =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        editItemPrice,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.allow86',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.allow86toolTip',
                )}
                checked={data ? data.registerPermissions.allow86 : false}
                onChange={allow86 =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        allow86,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.sendIndividualItems',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.sendIndividualItemstoolTip',
                )}
                checked={
                  data ? !!data.registerPermissions.sendIndividualItems : false
                }
                onChange={sendIndividualItems =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        sendIndividualItems,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
            </Box>
            <Divider direction="vertical" />
          </Grid.Item>
          <Grid.Item
            sm={6}
            md={6}
            lg={6}
            xl={3}
            csx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '15px',
            }}>
            <Box
              csx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '25px',
              }}>
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.modifyItemsSentToKitchen',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.modifyItemsSentToKitchentoolTip',
                )}
                checked={
                  data
                    ? data.registerPermissions.modifyItemsSentToKitchen
                    : false
                }
                onChange={modifyItemsSentToKitchen =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        modifyItemsSentToKitchen,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
              <Checkbox
                label={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.createOpenItemsOrModItems',
                )}
                info={t(
                  'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.createOpenItemsOrModItemstoolTip',
                )}
                checked={
                  data
                    ? data.registerPermissions.createOpenItemsOrModItems
                    : false
                }
                onChange={createOpenItemsOrModItems =>
                  setValue<IRoleSettingsStore>(
                    {
                      registerPermissions: {
                        ...data.registerPermissions,
                        createOpenItemsOrModItems,
                      },
                    },
                    SECTION_ID,
                  )
                }
              />
            </Box>
            <Divider direction="vertical" />
          </Grid.Item>
          <Grid.Item
            sm={6}
            md={6}
            lg={3}
            xl={3}
            csx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '25px',
            }}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.addAnyDiscount',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.addAnyDiscounttoolTip',
              )}
              checked={data ? data.registerPermissions.addAnyDiscount : false}
              onChange={addAnyDiscount =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      addAnyDiscount,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.addCustomDiscount',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForOwners.addCustomDiscounttoolTip',
              )}
              checked={
                data ? data.registerPermissions.addCustomDiscount : false
              }
              onChange={addCustomDiscount =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      addCustomDiscount,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>

          <Grid.Item>
            <Divider csx={{ marginBlock: '20px' }} />
            <DetailsSubtitle>
              {t(
                'settingsModule.roleDetailsSettings.register.printingPermissions.title',
              )}
            </DetailsSubtitle>
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.printingPermissions.reprint',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.printingPermissions.reprinttoolTip',
              )}
              checked={data ? data.registerPermissions.reprint : false}
              onChange={reprint =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      reprint,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.printingPermissions.deletePrintJobs',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.printingPermissions.deletePrintJobstoolTip',
              )}
              checked={data ? data.registerPermissions.deletePrintJobs : false}
              onChange={deletePrintJobs =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      deletePrintJobs,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>

          <Grid.Item>
            <Divider csx={{ marginBlock: '20px' }} />
            <DetailsSubtitle>
              {t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.title',
              )}
            </DetailsSubtitle>
            <Typography variant="caption">
              {t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.description',
              )}
            </Typography>
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.editOtherUsersOrders',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.editOtherUsersOrderstoolTip',
              )}
              checked={
                data ? data.registerPermissions.editOtherUsersOrders : false
              }
              onChange={editOtherUsersOrders =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      editOtherUsersOrders,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.overrideLicenseEditingId',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.overrideLicenseEditingIdtoolTip',
              )}
              checked={
                data ? data.registerPermissions.overrideLicenseEditingId : false
              }
              onChange={overrideLicenseEditingId =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      overrideLicenseEditingId,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.transferOrderOwner',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.orderPermissionsForNonOwners.transferOrderOwnertoolTip',
              )}
              checked={
                data ? data.registerPermissions.transferOrderOwner : false
              }
              onChange={transferOrderOwner =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      transferOrderOwner,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item>
            <Divider csx={{ marginBlock: '20px' }} />
            <DetailsSubtitle>
              {t('settingsModule.roleDetailsSettings.register.payments.title')}
            </DetailsSubtitle>
          </Grid.Item>
          <Grid.Item mb={6} lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.payments.createChecks',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.payments.createCheckstoolTip',
              )}
              checked={data ? data.registerPermissions.createChecks : false}
              onChange={createChecks =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      createChecks,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.payments.createPayments',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.payments.createPaymentstoolTip',
              )}
              checked={data ? data.registerPermissions.createPayments : false}
              onChange={createPayments =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      createPayments,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.payments.makeHouseChargePayments',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.payments.makeHouseChargePaymentstoolTip',
              )}
              checked={
                data
                  ? !!data.registerPermissions.makeHouseChargePayments
                  : false
              }
              onChange={makeHouseChargePayments =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      makeHouseChargePayments,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.payments.makeGiftCardPayments',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.payments.makeGiftCardPaymentstoolTip',
              )}
              checked={
                data ? !!data.registerPermissions.makeGiftCardPayments : false
              }
              onChange={makeGiftCardPayments =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      makeGiftCardPayments,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.payments.transferPayments',
              )}
              checked={data ? data.registerPermissions.transferPayments : false}
              onChange={transferPayments =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      transferPayments,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item mb={6} lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.payments.voidRefundPayments',
              )}
              info={t(
                'settingsModule.roleDetailsSettings.register.payments.voidRefundPaymentstoolTip',
              )}
              checked={
                data ? data.registerPermissions.voidRefundPayments : false
              }
              onChange={voidRefundPayments =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      voidRefundPayments,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.payments.batch',
              )}
              checked={data ? data.registerPermissions.batch : false}
              onChange={batch =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      batch,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item>
            <Divider csx={{ marginBlock: '20px' }} />
            <DetailsSubtitle>
              {t('settingsModule.roleDetailsSettings.register.drawers.title')}
            </DetailsSubtitle>
          </Grid.Item>
          <Grid.Item mb={6} lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.drawers.manageDrawers',
              )}
              checked={data ? data.registerPermissions.manageDrawers : false}
              onChange={manageDrawers =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      manageDrawers,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item mb={6} lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.drawers.viewDrawerBalance',
              )}
              checked={
                data ? data.registerPermissions.viewDrawerBalance : false
              }
              onChange={viewDrawerBalance =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      viewDrawerBalance,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
          <Grid.Item mb={6} lg={3}>
            <Checkbox
              label={t(
                'settingsModule.roleDetailsSettings.register.drawers.serverBankEnabled',
              )}
              checked={
                data ? data.registerPermissions.serverBankEnabled : false
              }
              onChange={serverBankEnabled =>
                setValue<IRoleSettingsStore>(
                  {
                    registerPermissions: {
                      ...data.registerPermissions,
                      serverBankEnabled,
                    },
                  },
                  SECTION_ID,
                )
              }
            />
          </Grid.Item>
        </Grid>
      </>
    </AccordionSection>
  );
};

export default RegisterSettings;
