import Box from '@app/components/common/Box';
import Table from '@app/components/common/Table';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ICustomizingReceiptDiff,
  IGuestCheckCustomizingReceiptDiff,
  IGuestCheckSettings,
  IKitchenTicketCustomizingReceipt,
  IPrepStationVersion,
  KitchenCustomizingReceiptsStatus,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import RenderChange from '../../common/RenderChange';

interface ICustomizingReceiptDiffTable extends WithTranslation {
  data: ICustomizingReceiptDiff[] | IGuestCheckCustomizingReceiptDiff[];
  field: 'ticketHeader' | 'ticketFooter' | 'ticketBody';
  changeData: IPrepStationVersion | IGuestCheckSettings;
  formatValue: (value: unknown, field: string, textValue?: string) => string;
  title?: string;
}

const columnHelper = createColumnHelper<
  ICustomizingReceiptDiff | IGuestCheckCustomizingReceiptDiff
>();
const CustomizingReceiptDiffTable = ({
  data,
  field,
  t,
  changeData,
  formatValue,
  title,
}: ICustomizingReceiptDiffTable) => {
  const defaultColumns = [
    columnHelper.accessor('changes', {
      id: 'order',
      header: t(
        'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.order',
      ),
      size: 40,
      cell: info => {
        const rowData = info.row.original;

        const isSeatNumberLocked =
          (changeData as IPrepStationVersion).groupBySeat &&
          field === 'ticketBody' &&
          rowData.id === 'seatNumber';

        const hasChanged = isSeatNumberLocked
          ? false
          : rowData.changes.some(change => change.field === 'order');

        return (
          <RenderChange
            autoWidth
            change={{
              field: 'order',
              label: '',
              value: isSeatNumberLocked
                ? '-'
                : (changeData as IPrepStationVersion)[field as 'ticketHeader'][
                    rowData.id as keyof IKitchenTicketCustomizingReceipt
                  ].order,
            }}
            textCsx={{
              ':first-letter': {
                textTransform: 'uppercase',
              },
            }}
            formatValue={formatValue}
            showLabel={false}
            hasChanged={hasChanged}
          />
        );
      },
    }),
    columnHelper.accessor('changes', {
      id: 'status',
      header: t(
        'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.status',
      ),
      cell: info => {
        const rowData = info.row.original;
        const hasChanged = rowData.changes.some(
          change => change.field === 'status',
        );

        return (
          <RenderChange
            change={{
              field: 'status',
              label: '',
              value:
                (changeData as IPrepStationVersion)[field as 'ticketHeader'][
                  rowData.id as keyof IKitchenTicketCustomizingReceipt
                ].status === KitchenCustomizingReceiptsStatus.ACTIVE
                  ? true
                  : false,
            }}
            textCsx={{
              ':first-letter': {
                textTransform: 'uppercase',
              },
            }}
            formatValue={formatValue}
            showLabel={false}
            hasChanged={hasChanged}
          />
        );
      },
    }),
    columnHelper.accessor('changes', {
      id: 'value',
      header: t(
        'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.value.label',
      ),
      size: 200,
      cell: info => {
        const rowData = info.row.original;

        return (
          <RenderChange
            change={{
              field: 'value',
              label: '',
              value: rowData.label,
            }}
            textCsx={{
              ':first-letter': {
                textTransform: 'uppercase',
              },
            }}
            formatValue={formatValue}
            showLabel={false}
            hasChanged={false}
          />
        );
      },
    }),
    columnHelper.accessor('changes', {
      id: 'prepend',

      header: t(
        'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.prepend',
      ),
      cell: info => {
        const rowData = info.row.original;
        const hasChanged = rowData.changes.some(
          change => change.field === 'prepend',
        );

        return (
          <RenderChange
            change={{
              field: 'prepend',
              label: '',
              value:
                (changeData as IPrepStationVersion)[field as 'ticketHeader'][
                  rowData.id as keyof IKitchenTicketCustomizingReceipt
                ].prepend ?? '',
            }}
            textCsx={{
              ':first-letter': {
                textTransform: 'uppercase',
              },
            }}
            formatValue={formatValue}
            showLabel={false}
            hasChanged={hasChanged}
          />
        );
      },
    }),
    columnHelper.accessor('changes', {
      id: 'append',
      header: t(
        'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.append',
      ),
      cell: info => {
        const rowData = info.row.original;
        const hasChanged = rowData.changes.some(
          change => change.field === 'append',
        );
        return (
          <RenderChange
            change={{
              field: 'append',
              label: '',
              value:
                (changeData as IPrepStationVersion)[field as 'ticketHeader'][
                  rowData.id as keyof IKitchenTicketCustomizingReceipt
                ].append ?? '',
            }}
            textCsx={{
              ':first-letter': {
                textTransform: 'uppercase',
              },
            }}
            formatValue={formatValue}
            showLabel={false}
            hasChanged={hasChanged}
          />
        );
      },
    }),
    columnHelper.accessor('changes', {
      id: 'justify',
      header: t(
        'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.justify.label',
      ),
      cell: info => {
        const rowData = info.row.original;
        const hasChanged = rowData.changes.some(
          change => change.field === 'justify',
        );
        return (
          <RenderChange
            change={{
              field: 'justify',
              label: '',
              value: (changeData as IPrepStationVersion)[
                field as 'ticketHeader'
              ][rowData.id as keyof IKitchenTicketCustomizingReceipt].justify,
            }}
            textCsx={{
              ':first-letter': {
                textTransform: 'uppercase',
              },
            }}
            formatValue={formatValue}
            showLabel={false}
            hasChanged={hasChanged}
          />
        );
      },
    }),
    columnHelper.accessor('changes', {
      id: 'fontSize',
      header: t(
        'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.fontSize.label',
      ),
      cell: info => {
        const rowData = info.row.original;
        const hasChanged = rowData.changes.some(
          change => change.field === 'fontSize',
        );
        return (
          <RenderChange
            change={{
              field: 'fontSize',
              label: '',
              value: (changeData as IPrepStationVersion)[
                field as 'ticketHeader'
              ][rowData.id as keyof IKitchenTicketCustomizingReceipt].fontSize,
            }}
            textCsx={{
              ':first-letter': {
                textTransform: 'uppercase',
              },
            }}
            formatValue={formatValue}
            showLabel={false}
            hasChanged={hasChanged}
          />
        );
      },
    }),
    columnHelper.accessor('changes', {
      id: 'fontWeight',
      header: t(
        'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.fontWeight.label',
      ),
      cell: info => {
        const rowData = info.row.original;
        const hasChanged = rowData.changes.some(
          change => change.field === 'fontWeight',
        );
        return (
          <RenderChange
            change={{
              field: 'fontWeight',
              label: '',
              value: (changeData as IPrepStationVersion)[
                field as 'ticketHeader'
              ][rowData.id as keyof IKitchenTicketCustomizingReceipt]
                .fontWeight,
            }}
            textCsx={{
              ':first-letter': {
                textTransform: 'uppercase',
              },
            }}
            formatValue={formatValue}
            showLabel={false}
            hasChanged={hasChanged}
          />
        );
      },
    }),
    columnHelper.accessor('changes', {
      id: 'color',
      header: t(
        'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.color',
      ),
      cell: info => {
        const rowData = info.row.original;
        const hasChanged = rowData.changes.some(
          change => change.field === 'color',
        );
        return (
          <RenderChange
            change={{
              field: 'color',
              label: '',
              value: (changeData as IPrepStationVersion)[
                field as 'ticketHeader'
              ][rowData.id as keyof IKitchenTicketCustomizingReceipt].color,
            }}
            textCsx={{
              ':first-letter': {
                textTransform: 'uppercase',
              },
            }}
            formatValue={formatValue}
            showLabel={false}
            hasChanged={hasChanged}
          />
        );
      },
    }),
    columnHelper.accessor('changes', {
      id: 'line',
      header: t(
        'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.line.label',
      ),
      cell: info => {
        const rowData = info.row.original;
        const hasChanged = rowData.changes.some(
          change => change.field === 'line',
        );
        return (
          <RenderChange
            change={{
              field: 'line',
              label: '',
              value: (changeData as IPrepStationVersion)[
                field as 'ticketHeader'
              ][rowData.id as keyof IKitchenTicketCustomizingReceipt].line,
            }}
            textCsx={{
              ':first-letter': {
                textTransform: 'uppercase',
              },
            }}
            formatValue={formatValue}
            showLabel={false}
            hasChanged={hasChanged}
          />
        );
      },
    }),
    columnHelper.accessor('changes', {
      id: 'noLineBreak',
      header: t(
        'loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.noLineBreak',
      ),
      cell: info => {
        const rowData = info.row.original;
        const hasChanged = rowData.changes.some(
          change => change.field === 'noLineBreak',
        );
        return (
          <RenderChange
            change={{
              field: 'noLineBreak',
              label: '',
              value: (changeData as IPrepStationVersion)[
                field as 'ticketHeader'
              ][rowData.id as keyof IKitchenTicketCustomizingReceipt]
                .noLineBreak,
            }}
            textCsx={{
              ':first-letter': {
                textTransform: 'uppercase',
              },
            }}
            formatValue={formatValue}
            showLabel={false}
            hasChanged={hasChanged}
          />
        );
      },
    }),
  ];
  return (
    <Box csx={{ width: '100%', paddingBlock: '10px' }}>
      <Table
        data={data}
        columns={defaultColumns}
        cellCsx={{ height: '70px' }}
        showShadow={false}
        alignHeaders={{
          order: 'center',
          status: 'center',
          value: 'center',
          prepend: 'center',
          append: 'center',
          justify: 'center',
          fontSize: 'center',
          fontWeight: 'center',
          color: 'center',
          line: 'center',
          noLineBreak: 'center',
        }}
        columnVisibility={{
          append: field === 'ticketBody' ? false : true,
          prepend: field === 'ticketBody' ? false : true,
          line: field === 'ticketBody' ? false : true,
          noLineBreak: field === 'ticketBody' ? false : true,
        }}
        title={
          title ||
          t(
            `loggedIn.settingsModule.kitchenTicketSettings.customizingReceiptsTable.title.${field}`,
          )
        }
      />
    </Box>
  );
};

export default CustomizingReceiptDiffTable;
