import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import LoadingModal from '@app/components/common/LoadingModal';
import NoElementsFound from '@app/components/common/NoElementsFound';
import OptionsModal from '@app/components/common/OptionsModal';
import Table from '@app/components/common/Table';
import { ETableModes } from '@app/components/common/Table/types';
import useGetPrinterJobs from '@app/hooks/useGetPrinterJobs';
import { actionCreatorsRegisterWeb } from '@app/state';
import { currentPrintersSelector } from '@app/state/selectors/settingsSelectors';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import { RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import {
  IPrinterJobSelected,
  TPrinterJobRow,
  actionCreatorsApp,
  actionCreatorsPrinterCenter,
  printersModalOptions,
} from '@westondev/tableturn-core';
import { useCallback, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { printerJobsStyles } from './styles';

interface IPrinterJobs extends WithTranslation {
  selectedPrinter: IPrinterJobSelected;
}
const PrinterJobs = ({ t, selectedPrinter }: IPrinterJobs) => {
  // Redux
  const dispatch = useDispatch();
  const { deletePrinterJobsWeb, transferPrinterJobsWeb } = bindActionCreators(
    actionCreatorsRegisterWeb,
    dispatch,
  );
  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    dispatch,
  );

  const clearPrinterErrorsForPrintJobs = bindActionCreators(
    actionCreatorsPrinterCenter.clearPrinterErrorsForPrintJobs,
    dispatch,
  );
  const printers = useSelector(currentPrintersSelector);

  const printersOptions = useMemo(
    () => printersModalOptions(printers || {}, selectedPrinter.printerId),
    [printers, selectedPrinter],
  );

  // Local state
  const columnHelper = createColumnHelper<TPrinterJobRow>();

  const [selectedJobs, setSelectedJobs] = useState<TPrinterJobRow[]>([]);
  const [tableKey, setTableKey] = useState(0);
  const { printerJobs, isLoading, refresh } = useGetPrinterJobs(
    selectedPrinter.printerId,
  );

  const COLUMNS = useMemo(() => {
    const columnsArray = [
      columnHelper.accessor('timePlaced', {
        header: t('navBar.common.printerCenter.printJobs.timePlaced'),
        cell: info => info.getValue(),
        minSize: 160,
      }),
      columnHelper.accessor('orderNumber', {
        header: t('navBar.common.printerCenter.printJobs.orderNumber'),
        cell: info => info.getValue(),
        size: 100,
      }),
      columnHelper.accessor('tabletPlacedFrom', {
        header: t('navBar.common.printerCenter.printJobs.tabletPlacedFrom'),
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('userPlaced', {
        header: t('navBar.common.printerCenter.printJobs.userPlacedFrom'),
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('type', {
        header: t('navBar.common.printerCenter.printJobs.printType'),
        cell: info => {
          const type = info.getValue();
          return t(`navBar.common.printerCenter.printJobs.jobType.${type}`);
        },
        size: 80,
      }),
    ];

    if (selectedPrinter.printerId === 0) {
      // put first a printerLocationName column
      columnsArray.unshift(
        columnHelper.accessor('printerLocationName', {
          header: t(
            'navBar.common.printerCenter.printJobs.printerLocationName',
          ),
          cell: info => info.getValue(),
          size: 160,
        }),
      );

      // put first a printerName column
      columnsArray.unshift(
        columnHelper.accessor('printerName', {
          header: t('navBar.common.printerCenter.printJobs.printerName'),
          cell: info => info.getValue(),
          size: 160,
        }),
      );
    }

    return columnsArray;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrinter.printerId]);

  const handleTransferOnChange = (printerId: number | null) => {
    if (!printerId || !selectedPrinter) return;
    const onlySelected = selectedJobs;

    const printJobsData = onlySelected.map(printJob => ({
      jobId: printJob.id,
      queueId: '',
    }));

    transferPrinterJobsWeb(
      {
        sourcePrinterId: selectedPrinter.printerId,
        printerId,
        printJobs: printJobsData,
      },
      () => {
        clearPrinterErrorsForPrintJobs(
          printJobsData.map(printJob => printJob.jobId),
        );
        setSelectedJobs([]);
        refreshPrinterJobs();
      },
      () => null,
    );
  };

  const refreshPrinterJobs = useCallback(() => {
    setTableKey(tableKey + 1);
    refresh();
  }, [setTableKey, tableKey, refresh]);

  const handleOnDeleteClick = useCallback(() => {
    setShowConfirmationModal({
      active: true,
      type: 'delete',
      overrideTitle: true,
      title: t(
        'navBar.common.printerCenter.printJobs.deleteConfirmation.title',
      ),
      description: t(
        'navBar.common.printerCenter.printJobs.deleteConfirmation.description',
      ),
      btnSuccessText: t('commonButtons.delete'),

      onSuccess: () => {
        const onlySelected = selectedJobs;

        const printJobsIds = onlySelected.map(printJob => printJob.id);
        deletePrinterJobsWeb(
          printJobsIds,
          () => {
            clearPrinterErrorsForPrintJobs(printJobsIds);

            setSelectedJobs([]);

            refreshPrinterJobs();
          },
          () => null,
        );
      },
    });
  }, [
    setShowConfirmationModal,
    t,
    selectedJobs,
    deletePrinterJobsWeb,
    clearPrinterErrorsForPrintJobs,
    refreshPrinterJobs,
  ]);

  const handleRowSelectionChange = (selectedRowsObj: RowSelectionState) => {
    const selectedRowsData = Object.keys(selectedRowsObj).map(
      rowIndex => printerJobs[Number(rowIndex)],
    );
    setSelectedJobs(selectedRowsData);
  };

  return (
    <Box csx={printerJobsStyles}>
      <LoadingModal
        mode="local"
        isLoadingLocal={{ active: isLoading, message: 'Loading Print Jobs' }}
      />

      <Box
        csx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <Box csx={[displayFlexRowStyles, { gap: '10px' }]}>
          <Button
            disabled={!selectedJobs.length}
            csx={{ minWidth: '120px' }}
            variant="danger"
            icon={<Icon name="MdDeleteForever" />}
            onClick={handleOnDeleteClick}>
            {t('commonButtons.delete')}
          </Button>
          <OptionsModal
            options={printersOptions}
            noElementMessage={t('navBar.common.printerCenter.noMorePrinters')}
            title={t('navBar.common.printerCenter.transferToPrinter')}
            value={0}
            buttonProps={{
              children: t('commonButtons.transfer'),
              variant: 'primary',
              csx: { minWidth: '120px' },
              disabled: true,
              iconPosition: 'left',
              icon: <Icon name="MdSyncAlt" />,
            }}
            onChange={handleTransferOnChange}
          />
        </Box>
      </Box>
      <Table
        key={tableKey}
        columns={COLUMNS}
        mode={ETableModes.SELECTION}
        data={printerJobs}
        onRowSelectionChange={handleRowSelectionChange}
        align={{
          printerName: 'center',
          printerLocationName: 'center',
          timePlaced: 'center',
          orderNumber: 'center',
          tabletPlacedFrom: 'center',
          userPlaced: 'center',
          type: 'center',
        }}
        alignHeaders={{
          printerName: 'center',
          printerLocationName: 'center',
          timePlaced: 'center',
          orderNumber: 'center',
          tabletPlacedFrom: 'center',
          userPlaced: 'center',
          type: 'center',
        }}
        noDataComponent={
          <NoElementsFound
            csx={{ paddingBlock: '15px' }}
            icon={<Icon name="MdPrint" color="semanticGrey" />}
            text={t('navBar.common.printerCenter.printJobs.noPrinterJobs')}
          />
        }
      />
    </Box>
  );
};

export default PrinterJobs;
