import {
  MdAdd,
  MdAddCircle,
  MdAddCircleOutline,
  MdApartment,
  MdArrowBack,
  MdArrowForward,
  MdBlockFlipped,
  MdCached,
  MdCenterFocusStrong,
  MdCheck,
  MdCheckCircle,
  MdChevronRight,
  MdCircle,
  MdClear,
  MdCode,
  MdContentCopy,
  MdCreditCard,
  MdDeleteForever,
  MdDownload,
  MdDragIndicator,
  MdEdit,
  MdExitToApp,
  MdFileCopy,
  MdFileUpload,
  MdFilterAlt,
  MdFormatClear,
  MdGrass,
  MdGridOn,
  MdGroups,
  MdHelp,
  MdHome,
  MdHotel,
  MdInsertEmoticon,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
  MdLightMode,
  MdLocalPlay,
  MdLocationOff,
  MdLocationPin,
  MdLock,
  MdLockOutline,
  MdMap,
  MdMenu,
  MdModeNight,
  MdMoreHoriz,
  MdMultipleStop,
  MdMyLocation,
  MdNotifications,
  MdOutlineAdd,
  MdOutlineCheckCircle,
  MdOutlineCreditCardOff,
  MdOutlineEmail,
  MdOutlineRotateLeft,
  MdOutlineRotateRight,
  MdOutlineStar,
  MdOutlineStarBorder,
  MdOutlineWindow,
  MdOutlineZoomOutMap,
  MdPause,
  MdPeople,
  MdPerson,
  MdPhoneIphone,
  MdPlayArrow,
  MdPrint,
  MdRedo,
  MdRemove,
  MdRequestQuote,
  MdRestaurant,
  MdSave,
  MdSensorDoor,
  MdSettings,
  MdSortByAlpha,
  MdSubtitlesOff,
  MdSupervisedUserCircle,
  MdSyncAlt,
  MdUndo,
  MdVisibility,
  MdVisibilityOff,
  MdWarning,
  MdWrapText,
} from 'react-icons/md';

import { BsBuildingFill } from 'react-icons/bs';

import {
  FaCircle,
  FaInfo,
  FaLink,
  FaRegCircle,
  FaSearch,
  FaSortAlphaDown,
  FaSortAlphaDownAlt,
  FaSortAlphaUp,
  FaSortAlphaUpAlt,
  FaTrashAlt,
  FaUndo,
  FaUserClock,
} from 'react-icons/fa';

import { FaHouseChimney, FaXmark } from 'react-icons/fa6';

import CustomLayers from '@app/assets/icons/layers.svg?react';
import TaxInclusive from '@app/assets/icons/tax-inclusive.svg?react';
import { IoTriangleSharp, IoWarning } from 'react-icons/io5';
import { LiaVectorSquareSolid } from 'react-icons/lia';

const MATERIAL_DESIGN_ICONS = {
  MdInsertEmoticon,
  MdVisibility,
  MdVisibilityOff,
  MdHelp,
  MdOutlineCheckCircle,
  MdClear,
  FaSearch,
  MdSortByAlpha,
  MdPerson,
  MdCheckCircle,
  MdAddCircle,
  MdChevronRight,
  MdLockOutline,
  MdOutlineEmail,
  MdCached,
  MdPhoneIphone,
  MdAddCircleOutline,
  MdLightMode,
  MdModeNight,
  MdExitToApp,
  MdRestaurant,
  MdSettings,
  MdRequestQuote,
  MdSupervisedUserCircle,
  MdLocalPlay,
  MdHome,
  MdCode,
  MdPrint,
  MdNotifications,
  MdMultipleStop,
  MdMenu,
  MdLocationPin,
  MdCheck,
  MdDragIndicator,
  MdArrowBack,
  MdAdd,
  MdDownload,
  MdDeleteForever,
  MdFileCopy,
  MdFileUpload,
  MdPeople,
  MdGroups,
  MdCreditCard,
  MdOutlineRotateLeft,
  MdEdit,
  MdFilterAlt,
  MdSave,
  MdWarning,
  MdArrowForward,
  MdFormatClear,
  MdLock,
  MdSubtitlesOff,
  MdOutlineAdd,
  MdLocationOff,
  MdMoreHoriz,
  MdUndo,
  MdRedo,
  MdGridOn,
  MdOutlineZoomOutMap,
  MdMap,
  MdMyLocation,
  MdGrass,
  MdSensorDoor,
  MdOutlineWindow,
  MdCenterFocusStrong,
  MdRemove,
  MdContentCopy,
  MdOutlineRotateRight,
  MdPause,
  MdPlayArrow,
  MdSyncAlt,
  MdCircle,
  MdOutlineCreditCardOff,
  MdWrapText,
  MdKeyboardDoubleArrowUp,
  MdKeyboardDoubleArrowDown,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdBlockFlipped,
  MdHotel,
  MdApartment,
  MdOutlineStar,
  MdOutlineStarBorder,
};

const FONT_AWESOME_ICONS = {
  FaInfo,
  FaSortAlphaUp,
  FaSortAlphaDown,
  FaSortAlphaUpAlt,
  FaSortAlphaDownAlt,
  FaUserClock,
  FaTrashAlt,
  FaUndo,
  FaRegCircle,
  FaCircle,
  FaLink,
  FaXmark,
  FaHouseChimney,
};
const IO5_ICONS = {
  IoTriangleSharp,
  IoWarning,
};

const LIA_ICONS = {
  LiaVectorSquareSolid,
};
const BS_ICONS = {
  BsBuildingFill,
};

const CUSTOM_ICONS = {
  CustomLayers,
  TaxInclusive,
};

export const ICONS = {
  ...MATERIAL_DESIGN_ICONS,
  ...FONT_AWESOME_ICONS,
  ...IO5_ICONS,
  ...LIA_ICONS,
  ...CUSTOM_ICONS,
  ...BS_ICONS,
};

export type TIcons = keyof typeof ICONS;
