import { IDropdownItem } from '@app/components/common/Dropdown/types';
import {
  DefaultTipInput,
  formatMoney,
  IRolesSettingsStore,
  OrderType,
  ServiceChargeAssignTo,
  ServiceChargeDiscount,
  ServiceChargeType,
  SplitTipInterval,
  TipDistribution,
  TipPayoutMethods,
  TipShareBy,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';

export const valueToTipPayoutMethods: { [key: string]: TipPayoutMethods } = {
  0: TipPayoutMethods.MANUAL,
  1: TipPayoutMethods.PAYROLL,
};

export const tipPayoutMethodsToValue: { [key: string]: number } = {
  [TipPayoutMethods.MANUAL]: 0,
  [TipPayoutMethods.PAYROLL]: 1,
};

export const tipDistributionToValue: { [key: string]: number } = {
  [TipDistribution.INDIVIDUAL]: 0,
  [TipDistribution.POOLING]: 1,
  [TipDistribution.SHARING]: 2,
};

export const defaultTipInputToValue: { [key: string]: number } = {
  [DefaultTipInput.TIP_AMOUNT]: 0,
  [DefaultTipInput.NEW_TOTAL]: 1,
};

export const valueToDefaultTipInput: { [key: string]: DefaultTipInput } = {
  0: DefaultTipInput.TIP_AMOUNT,
  1: DefaultTipInput.NEW_TOTAL,
};

export const valueToOrderType: { [key: string]: OrderType } = {
  0: OrderType.CATERING,
  1: OrderType.DELIVERY,
  2: OrderType.DINE_IN,
  3: OrderType.TO_GO,
};

export enum ServiceChargeTypeOptions {
  FIXED,
  PERCENT,
  CUSTOM_DOLLAR,
  CUSTOM_PERCENT,
}

export const valueToChargeType: { [key: string]: ServiceChargeType } = {
  [ServiceChargeTypeOptions.FIXED]: ServiceChargeType.FIXED,
  [ServiceChargeTypeOptions.PERCENT]: ServiceChargeType.PERCENT,
  [ServiceChargeTypeOptions.CUSTOM_DOLLAR]: ServiceChargeType.CUSTOM_DOLLAR,
  [ServiceChargeTypeOptions.CUSTOM_PERCENT]: ServiceChargeType.CUSTOM_PERCENT,
};

export const minGuestsOptions = (maxGuests = 20): IDropdownItem[] => {
  const options = [];
  for (let i = 0; i <= maxGuests; i++) {
    options.push({ label: i.toString(), value: i });
  }
  return options;
};

export const valueToChargeDiscount: { [key: string]: ServiceChargeDiscount } = {
  0: ServiceChargeDiscount.PRE_DISCOUNT,
  1: ServiceChargeDiscount.POST_DISCOUNT,
};

export const chargeTypeToValue: { [key: string]: number } = {
  [ServiceChargeType.FIXED]: 0,
  [ServiceChargeType.PERCENT]: 1,
};

export const chargeDiscountToValue: { [key: string]: number } = {
  [ServiceChargeDiscount.PRE_DISCOUNT]: 0,
  [ServiceChargeDiscount.POST_DISCOUNT]: 1,
};

export const valueToAssignTo: { [key: string]: ServiceChargeAssignTo } = {
  0: ServiceChargeAssignTo.SERVER,
  1: ServiceChargeAssignTo.RESTAURANT,
};

export const assignToToValue: { [key: string]: number } = {
  [ServiceChargeAssignTo.SERVER]: 0,
  [ServiceChargeAssignTo.RESTAURANT]: 1,
};

export const formatServiceChargeAmount = (
  type: ServiceChargeType,
  amount: number,
): string => {
  if (type === ServiceChargeType.PERCENT) return `${amount}%`;
  else if (type === ServiceChargeType.FIXED) {
    return formatMoney(null, amount);
  }

  return 'N/A';
};

export const tipShareByToValue: { [key: string]: number } = {
  [TipShareBy.ROLE]: 0,
  [TipShareBy.MENU_TYPE]: 1,
  [TipShareBy.CATEGORY]: 2,
  [TipShareBy.SUBCATEGORY]: 3,
  [TipShareBy.ITEM]: 4,
};

export const valueToTipShareBy: { [key: string]: TipShareBy } = {
  0: TipShareBy.ROLE,
  1: TipShareBy.MENU_TYPE,
  2: TipShareBy.CATEGORY,
  3: TipShareBy.SUBCATEGORY,
  4: TipShareBy.ITEM,
};

export const orderTypeToValue: { [key: string]: number } = {
  [OrderType.CATERING]: 0,
  [OrderType.DELIVERY]: 1,
  [OrderType.DINE_IN]: 2,
  [OrderType.TO_GO]: 3,
};

export const valueToTipDistribution: { [key: string]: TipDistribution } = {
  0: TipDistribution.INDIVIDUAL,
  1: TipDistribution.POOLING,
  2: TipDistribution.SHARING,
};

export const splitTipIntervalToValue: { [key: string]: number } = {
  [SplitTipInterval.SHIFT]: 0,
  [SplitTipInterval.DAY]: 1,
};

export const valueToSplitTipInterval: { [key: string]: SplitTipInterval } = {
  0: SplitTipInterval.SHIFT,
  1: SplitTipInterval.DAY,
};

export const rolesDropdownOptions = (
  roles: IRolesSettingsStore,
  currentRoles?: number[],
): IDropdownItem[] => {
  const toRemoveRoles = new Set(currentRoles);

  return Object.keys(roles)
    .map(roleId => ({
      label: roles[roleId].name,
      value: Number(roleId),
    }))
    .filter(({ value }) => !toRemoveRoles.has(value));
};

export const generateOrderTypeOptions = (
  t: WithTranslation['t'],
  orderTypesAvailable: {
    isCateringAvailable: boolean;
    isDeliveryAvailable: boolean;
    isDineInAvailable: boolean;
    isToGoAvailable: boolean;
  },
): IDropdownItem[] => {
  const options = [];
  if (orderTypesAvailable.isDineInAvailable) {
    options.push({
      label: t('settingsModule.registerModeSettings.general.orderTypes.dineIn'),
      value: orderTypeToValue[OrderType.DINE_IN],
    });
  }
  if (orderTypesAvailable.isDeliveryAvailable) {
    options.push({
      label: t(
        'settingsModule.registerModeSettings.general.orderTypes.delivery',
      ),
      value: orderTypeToValue[OrderType.DELIVERY],
    });
  }
  if (orderTypesAvailable.isToGoAvailable) {
    options.push({
      label: t('settingsModule.registerModeSettings.general.orderTypes.toGo'),
      value: orderTypeToValue[OrderType.TO_GO],
    });
  }
  if (orderTypesAvailable.isCateringAvailable) {
    options.push({
      label: t(
        'settingsModule.registerModeSettings.general.orderTypes.catering',
      ),
      value: orderTypeToValue[OrderType.CATERING],
    });
  }

  return options;
};
