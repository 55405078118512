import { WithTranslation } from 'react-i18next';
import Checkbox from '../Checkbox';
import { ICheckbox } from '../Checkbox/types';
import Icon from '../Icon';
type IStarCheckBox = Omit<ICheckbox, 'icon'> & WithTranslation;

const START_SIZE = '30px';
const START_STYLE = {
  marginRight: '5px',
};

const StarCheckbox = (props: IStarCheckBox) => {
  return (
    <Checkbox
      icon={{
        checked: (
          <Icon
            csx={START_STYLE}
            size={START_SIZE}
            color="semanticYellow"
            name="MdOutlineStar"
          />
        ),
        unChecked: (
          <Icon
            csx={START_STYLE}
            size={START_SIZE}
            color="lightGrey"
            name="MdOutlineStarBorder"
          />
        ),
      }}
      label={props.t(
        `components.starCheckBox.${props.checked ? 'checked' : 'unChecked'}`,
      )}
      {...props}
    />
  );
};

export default StarCheckbox;
