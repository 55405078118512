import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import ItemReportingGroupsSubsection from '../ReportingGroupsSection/ItemReportingGroupsSubsection';
import { IItemDetailsSection } from '../types';

const MassUpdateReportingGroupsSection = (props: IItemDetailsSection) => {
  const { t } = props;

  return (
    <AccordionSection
      title={t('menuScreen.itemDetails.reportingSection.title')}
      {...props}>
      <Box csx={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        <ItemReportingGroupsSubsection {...props} />
        <Divider />
        <ItemReportingGroupsSubsection
          reportingGroupsField="removeReportingGroupIds"
          {...props}
        />
      </Box>
    </AccordionSection>
  );
};

export default MassUpdateReportingGroupsSection;
