import Accordion from '@app/components/common/Accordion';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import MoneyInput from '@app/components/common/MoneyInput';
import Switch from '@app/components/common/Switch';
import TimePicker from '@app/components/common/TimePicker/TimePicker';
import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import BreadCrumbSettings from '@app/components/LoggedIn/Settings/BreadCrumbSettings';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  currentTaxesSettingsSelector,
  makeSelectSettingsData,
  selectRoles,
  settingsErrorsSelector,
} from '@app/state/selectors/settingsSelectors';
import {
  defaultTipInputToValue,
  splitTipIntervalToValue,
  tipDistributionToValue,
  tipPayoutMethodsToValue,
  tipShareByToValue,
  valueToDefaultTipInput,
  valueToSplitTipInterval,
  valueToTipDistribution,
  valueToTipPayoutMethods,
  valueToTipShareBy,
} from '@app/state/settings/paymentsFactory';
import { loadSettingsBucketChangeData as loadSettingsBucketChangeDataAction } from '@app/state/settings/settingsWebActions';
import { store, useAppDispatch } from '@app/state/store';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { IGenericError } from '@app/types';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  actionCreatorsSettings,
  actionCreatorsSettingsChangeData,
  generateTabletGeneratedId,
  IPaymentsSettings,
  ITipRolePool,
  ITipRolesPool,
  ITipShareRule,
  ITipSharingRules,
  ServiceChargeAssignTo,
  ServiceChargeDiscount,
  SettingsSectionId,
  SplitTipInterval,
  TipDistribution,
  TipShareBy,
} from '@westondev/tableturn-core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ServiceCharge from './ServiceCharge';
import ServiceChargeForm from './ServiceCharge/ServiceChargeForm';
import TipRolePoolTable from './TipRolePoolTable';
import TipSharingRuleModal from './TipSharingRuleModal';
import TipSharingRulesTable from './TipSharingRulesTable';

const SECTION_ID = SettingsSectionId.PAYMENTS;
const getMemoizedItemData =
  makeSelectSettingsData<IPaymentsSettings>(SECTION_ID);

const gridItemSizing = {
  mb: 12,
  sm: 6,
  md: 6,
  lg: 4,
  xl: 3,
};

const Payments = ({ t }: WithTranslation) => {
  const dispatch = useAppDispatch();
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    dispatch,
  );
  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    dispatch,
  );

  const loadSettingsBucketChangeData = bindActionCreators(
    loadSettingsBucketChangeDataAction,
    dispatch,
  );

  const [selectedSharingRule, setSelectedSharingRule] = useState<
    number | string | null
  >(null);

  const [serviceChargeId, setServiceChargeId] = useState<
    number | string | null
  >(null);

  const roles = useSelector(selectRoles);
  const settingsTaxes = useSelector(currentTaxesSettingsSelector);

  const { data } = useSelector(getMemoizedItemData);
  const isSet = useRootSelector(
    state => state.settings.changeData.settingsSelected === 'payments',
  );
  const settingsErrors = useSelector(settingsErrorsSelector);

  useEffect(() => {
    if (!isSet) loadSettingsBucketChangeData({ setting: 'payments' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet]);

  const batchTimeError =
    (settingsErrors?.batchTime?.data as IGenericError)?.message || '';

  const rolePoolRecipientsData = useMemo(() => {
    if (!data?.rolePoolRecipients) return [];
    return Object.values(data?.rolePoolRecipients || {}).map(role => ({
      id: role.id,
      roleId: role.roleId,
      amount: role.amount,
      actions: '',
    }));
  }, [data?.rolePoolRecipients]);

  const rolePoolContributorsData = useMemo(() => {
    return Object.values(data?.rolePoolContributors || {}).map(role => ({
      id: role.id,
      roleId: role.roleId,
      amount: role.amount,
      actions: '',
    }));
  }, [data?.rolePoolContributors]);

  const formatSharingRulesRecipients = useCallback(
    (recipients: ITipRolesPool) => {
      const recipientsText: string[] = [];

      Object.values(recipients).forEach(role => {
        recipientsText.push(`${roles?.[role.roleId]?.name}: ${role.amount}%`);
      });

      return recipientsText.length === 0 ? '- - -' : recipientsText.join(' , ');
    },
    [roles],
  );

  const serviceChargeArray = useMemo(
    () =>
      Object.keys(data?.serviceCharges || {}).map(key => {
        const taxes = data.serviceCharges[key].taxIds
          .map(taxId => {
            const tax = settingsTaxes[taxId];
            return tax ? tax.name : '';
          })
          .join(', ');

        const discount =
          data.serviceCharges[key].discount ===
          ServiceChargeDiscount.PRE_DISCOUNT
            ? t(
                'settingsModule.paymentsSettings.serviceChargeSettings.preDiscount',
              )
            : t(
                'settingsModule.paymentsSettings.serviceChargeSettings.postDiscount',
              );

        const assignTo =
          data.serviceCharges[key].assignTo === ServiceChargeAssignTo.SERVER
            ? t('settingsModule.paymentsSettings.serviceChargeSettings.server')
            : t(
                'settingsModule.paymentsSettings.serviceChargeSettings.restaurant',
              );

        return {
          ...data.serviceCharges[key],
          id: Number(key),
          taxes,
          orderTypes: data.serviceCharges[key].orderTypes,
          discount,
          assignTo,
          actions: '',
        };
      }),

    [data, settingsTaxes, t],
  );

  const sharingRulesData = useMemo(() => {
    return Object.values(data?.sharingRules || {}).map(rule => ({
      id: rule.id,
      contributor: roles[rule.contributorId]?.name,
      contribution: rule.contribution,
      recipients: formatSharingRulesRecipients(rule.recipients),
      actions: '',
    }));
  }, [data?.sharingRules, roles, formatSharingRulesRecipients]);

  const updateValue = (
    field: keyof IPaymentsSettings,
    newValue: number | string | Partial<ITipRolePool> | Partial<ITipShareRule>,
    id?: number | string,
  ) => {
    const currentData = store.getState().settings.changeData
      .data as IPaymentsSettings;

    if (
      id &&
      (field === 'rolePoolContributors' ||
        field === 'rolePoolRecipients' ||
        field === 'sharingRules')
    ) {
      setValue<IPaymentsSettings>(
        {
          [field]: {
            ...currentData[field],
            [id]: {
              ...(currentData[field] as ITipRolesPool)[id],
              ...(newValue as Partial<ITipRolePool>),
            },
          },
        },
        SECTION_ID,
      );
      return;
    }

    setValue<IPaymentsSettings>(
      {
        ...currentData,
        [field]: newValue,
      },
      SECTION_ID,
    );
  };

  const deleteSharingRule = (id: number) => {
    const newData = { ...data?.sharingRules };
    delete newData[id];
    setValue(
      {
        sharingRules: newData,
      },
      SECTION_ID,
    );
  };

  const handleTipDistributionChange = (value: number) => {
    if (data?.tipDistribution === valueToTipDistribution[value]) return;
    const newFields: Partial<IPaymentsSettings> = {};
    if (
      data?.tipDistribution === TipDistribution.INDIVIDUAL &&
      valueToTipDistribution[value] !== TipDistribution.INDIVIDUAL
    ) {
      newFields.splitTipInterval = SplitTipInterval.SHIFT;
    } else if (
      data?.tipDistribution !== TipDistribution.INDIVIDUAL &&
      valueToTipDistribution[value] === TipDistribution.INDIVIDUAL
    ) {
      newFields.splitTipInterval = null;
    }

    if (valueToTipDistribution[value] === TipDistribution.SHARING) {
      newFields.shareBy = TipShareBy.ROLE;
      newFields.sharingRules = {};
    } else {
      newFields.shareBy = null;
      newFields.sharingRules = null;
    }

    if (valueToTipDistribution[value] === TipDistribution.POOLING) {
      newFields.rolePoolContributors = {};
      newFields.rolePoolRecipients = {};
    } else {
      newFields.rolePoolContributors = null;
      newFields.rolePoolRecipients = null;
    }

    setValue(
      {
        tipDistribution: valueToTipDistribution[value],
        ...newFields,
      },
      SECTION_ID,
    );
  };

  const addRolePool = (key: 'rolePoolContributors' | 'rolePoolRecipients') => {
    if (Object.keys(roles).length === 0) return;
    const currentData = store.getState().settings.changeData
      .data as IPaymentsSettings;
    const currentRoles = new Set(
      Object.values(currentData[key] as ITipRolesPool).map(role => role.roleId),
    );

    let newRoleId = 0;
    for (const roleId of Object.keys(roles)) {
      if (!currentRoles.has(Number(roleId))) {
        newRoleId = Number(roleId);
        break;
      }
    }
    const newId = generateTabletGeneratedId();

    setValue(
      {
        [key]: {
          ...currentData[key],
          [newId]: {
            id: newId,
            roleId: newRoleId,
            amount: 0,
          },
        },
      },
      SECTION_ID,
    );
  };

  const addSharingRule = () => {
    if (Object.keys(roles).length === 0) return;

    setSelectedSharingRule(0);
  };

  const deleteRolePool = (
    key: 'rolePoolContributors' | 'rolePoolRecipients',
    id: number,
  ) => {
    const currentData = store.getState().settings.changeData
      .data as IPaymentsSettings;
    const newData = { ...currentData[key] };
    delete newData[id];
    setValue(
      {
        [key]: newData,
      },
      SECTION_ID,
    );
  };

  const generateSharingRuleObject = () => {
    if (Object.keys(roles).length === 0) return null;

    const sharingRules = (data?.sharingRules as ITipSharingRules) || {};
    const currentRoles = new Set(
      Object.values(sharingRules).map(rule => rule.contributorId),
    );

    let newContributorId = 0;
    for (const id of Object.keys(roles)) {
      if (!currentRoles.has(Number(id))) {
        newContributorId = Number(id);
        break;
      }
    }

    const newId = generateTabletGeneratedId();
    return {
      id: newId,
      contributorId: newContributorId,
      contribution: 0,
      recipients: {},
    };
  };

  const serviceChargeName = useMemo(() => {
    if (!data || !serviceChargeId) {
      return '';
    }
    if (typeof serviceChargeId === 'string') {
      return 'Adding Service Charge';
    }

    if (data.serviceCharges[serviceChargeId]) {
      return data.serviceCharges[serviceChargeId].name;
    }

    return '';
  }, [data, serviceChargeId]);

  return (
    <>
      <TipSharingRuleModal
        data={
          selectedSharingRule !== null
            ? (data?.sharingRules || {})[selectedSharingRule] ??
              generateSharingRuleObject()
            : null
        }
        sharingRulesData={data?.sharingRules || {}}
        active={selectedSharingRule !== null}
        onCancel={() => setSelectedSharingRule(null)}
        onSuccess={() => setSelectedSharingRule(null)}
        updateValue={updateValue}
      />
      {serviceChargeId ? (
        <>
          <BreadCrumbSettings
            title="Payments"
            currentPage={serviceChargeName}
            onClick={() => setServiceChargeId(null)}
            isSticky
          />
          <ServiceChargeForm
            serviceChargeId={serviceChargeId}
            navigateBack={() => {
              setServiceChargeId(null);
            }}
          />
        </>
      ) : (
        <Accordion>
          <AccordionSection
            isExpanded
            title={t('settingsModule.paymentsSettings.general.title')}
            description={t(
              'settingsModule.paymentsSettings.general.description',
            )}>
            <Grid
              rowGap={10}
              columnGap={20}
              csx={{
                width: '100%',
              }}>
              <Grid.Item {...gridItemSizing} csx={displayFlexEndColumnStyles}>
                <Checkbox
                  label={t(
                    'settingsModule.paymentsSettings.general.confirmDrawerStartingBalance',
                  )}
                  checked={data?.confirmDrawerStartingBalance}
                  onChange={checked => {
                    setValue(
                      {
                        confirmDrawerStartingBalance: checked,
                      },
                      SECTION_ID,
                    );
                  }}
                />
              </Grid.Item>

              <Grid.Item {...gridItemSizing} csx={displayFlexEndColumnStyles}>
                <Checkbox
                  label={t(
                    'settingsModule.paymentsSettings.general.printChecksOnCloseOrder',
                  )}
                  checked={data?.printChecksOnCloseOrder}
                  onChange={checked => {
                    setValue(
                      {
                        printChecksOnCloseOrder: checked,
                      },
                      SECTION_ID,
                    );
                  }}
                />
              </Grid.Item>
              <Grid.Item {...gridItemSizing} csx={displayFlexEndColumnStyles}>
                <Checkbox
                  label={t(
                    'settingsModule.paymentsSettings.general.forceAllPaidOrdersToTipPending',
                  )}
                  checked={data?.forceAllPaidOrdersToTipPending}
                  onChange={checked => {
                    setValue(
                      {
                        forceAllPaidOrdersToTipPending: checked,
                      },
                      SECTION_ID,
                    );
                  }}
                />
              </Grid.Item>
              <Grid.Item {...gridItemSizing} csx={displayFlexEndColumnStyles}>
                <Checkbox
                  label={t(
                    'settingsModule.paymentsSettings.general.requireManualClosingPostTip',
                  )}
                  checked={data?.requireManualClosingPostTip}
                  onChange={checked => {
                    setValue(
                      {
                        requireManualClosingPostTip: checked,
                      },
                      SECTION_ID,
                    );
                  }}
                />
              </Grid.Item>
            </Grid>
            <Divider
              csx={{
                marginBlock: '20px',
              }}
            />
            <DetailsSubtitle>
              {t(
                'settingsModule.paymentsSettings.general.preAuthOptions.title',
              )}
            </DetailsSubtitle>
            <Grid
              rowGap={10}
              columnGap={20}
              csx={{
                width: '100%',
              }}>
              <Grid.Item {...gridItemSizing} csx={displayFlexEndColumnStyles}>
                <MoneyInput
                  value={data?.defaultPreAuthAmount || 0}
                  label={t(
                    'settingsModule.paymentsSettings.general.preAuthOptions.defaultPreAuthAmount',
                  )}
                  suffix=""
                  precision={0}
                  onValueChange={value => {
                    setValue(
                      {
                        defaultPreAuthAmount: value,
                      },
                      SECTION_ID,
                    );
                  }}
                  maxValue={9999}
                />
              </Grid.Item>
              <Grid.Item
                {...gridItemSizing}
                xl={4}
                csx={[
                  displayFlexEndColumnStyles,
                  {
                    alignSelf: 'center',
                  },
                ]}>
                <Checkbox
                  label={t(
                    'settingsModule.paymentsSettings.general.preAuthOptions.dueExceedsPreAuth',
                  )}
                  checked={data?.dueExceedsPreAuth || false}
                  onChange={checked => {
                    setValue(
                      {
                        dueExceedsPreAuth: checked,
                      },
                      SECTION_ID,
                    );
                  }}
                />
              </Grid.Item>
            </Grid>
            <DetailsSubtitle textCsx={{ marginTop: '20px' }} noMargin>
              {t('settingsModule.paymentsSettings.general.batchSettings.title')}
            </DetailsSubtitle>
            <Grid
              rowGap={10}
              columnGap={20}
              csx={{
                width: '100%',
              }}>
              <Grid.Item
                {...gridItemSizing}
                csx={[
                  displayFlexEndColumnStyles,
                  { paddingBottom: batchTimeError ? '20px' : '0px' },
                ]}>
                <Switch
                  label={t(
                    'settingsModule.paymentsSettings.general.batchSettings.automatedBatching',
                  )}
                  checked={data?.automatedBatching || false}
                  onChange={automatedBatching =>
                    setValue({ automatedBatching }, SECTION_ID)
                  }
                />
              </Grid.Item>
              <Grid.Item
                {...gridItemSizing}
                xl={4}
                csx={displayFlexEndColumnStyles}>
                <TimePicker
                  label={t(
                    'settingsModule.paymentsSettings.general.batchSettings.batchTime',
                  )}
                  utcValue={data?.batchTime}
                  onChange={newTime =>
                    setValue({ batchTime: newTime }, SECTION_ID)
                  }
                  error={Boolean(batchTimeError)}
                  caption={batchTimeError}
                  onFocus={() =>
                    batchTimeError && clearSettingsError('batchTime')
                  }
                />
              </Grid.Item>
            </Grid>
          </AccordionSection>
          <AccordionSection
            title={t('settingsModule.paymentsSettings.drawerSettings.title')}
            description={t(
              'settingsModule.paymentsSettings.drawerSettings.description',
            )}></AccordionSection>
          <AccordionSection
            title={t('settingsModule.paymentsSettings.tipSettings.title')}>
            <Box
              csx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}>
              <Grid
                rowGap={20}
                columnGap={20}
                csx={{ alignItems: 'flex-start' }}>
                <Grid.Item {...gridItemSizing} csx={displayFlexEndColumnStyles}>
                  <Dropdown
                    label={t(
                      'settingsModule.paymentsSettings.tipSettings.tipPayoutMethod',
                    )}
                    data={[
                      [
                        {
                          label: t(
                            'settingsModule.paymentsSettings.tipSettings.manual',
                          ),
                          value: 0,
                        },
                        {
                          label: t(
                            'settingsModule.paymentsSettings.tipSettings.payroll',
                          ),
                          value: 1,
                        },
                      ],
                    ]}
                    value={tipPayoutMethodsToValue[data?.tipPayoutMethod] || 0}
                    onChange={value => {
                      updateValue(
                        'tipPayoutMethod',
                        valueToTipPayoutMethods[value],
                      );
                    }}
                    isDisabled
                    placeholder=""
                  />
                </Grid.Item>
                <Grid.Item {...gridItemSizing} csx={displayFlexEndColumnStyles}>
                  <Dropdown
                    label={t(
                      'settingsModule.paymentsSettings.tipSettings.tipDistribution',
                    )}
                    data={[
                      [
                        {
                          label: t(
                            'settingsModule.paymentsSettings.tipSettings.individual',
                          ),
                          value: 0,
                        },
                        {
                          label: t(
                            'settingsModule.paymentsSettings.tipSettings.pooling',
                          ),
                          value: 1,
                        },
                        {
                          label: t(
                            'settingsModule.paymentsSettings.tipSettings.sharing',
                          ),
                          value: 2,
                        },
                      ],
                    ]}
                    value={tipDistributionToValue[data?.tipDistribution] || 0}
                    onChange={value => handleTipDistributionChange(value)}
                    placeholder=""
                  />
                </Grid.Item>
                <Grid.Item {...gridItemSizing} csx={displayFlexEndColumnStyles}>
                  <Dropdown
                    label={t(
                      'settingsModule.paymentsSettings.tipSettings.defaultTipInput',
                    )}
                    data={[
                      [
                        {
                          label: t(
                            'settingsModule.paymentsSettings.tipSettings.tipAmount',
                          ),
                          value: 0,
                        },
                        {
                          label: t(
                            'settingsModule.paymentsSettings.tipSettings.newTotal',
                          ),
                          value: 1,
                        },
                      ],
                    ]}
                    value={defaultTipInputToValue[data?.defaultTipInput] || 0}
                    onChange={value =>
                      setValue(
                        {
                          defaultTipInput: valueToDefaultTipInput[value],
                        },
                        SECTION_ID,
                      )
                    }
                  />
                </Grid.Item>
              </Grid>
              {data?.tipDistribution === TipDistribution.POOLING && (
                <>
                  <Divider />
                  <DetailsSubtitle noMargin>
                    {t(
                      'settingsModule.paymentsSettings.tipSettings.poolingRules',
                    )}
                  </DetailsSubtitle>

                  <TipRolePoolTable
                    title={t(
                      'settingsModule.paymentsSettings.tipSettings.rolesContributingToPool',
                    )}
                    firstColumnTitle={t(
                      'settingsModule.paymentsSettings.tipSettings.contributor',
                    )}
                    secondColumnTitle={t(
                      'settingsModule.paymentsSettings.tipSettings.percentageContributed',
                    )}
                    hasZeroText={t(
                      'settingsModule.paymentsSettings.tipSettings.percentageContributedRequired',
                    )}
                    data={rolePoolContributorsData}
                    updateValue={(
                      newValue: Partial<ITipRolePool>,
                      id: number | string,
                    ) => updateValue('rolePoolContributors', newValue, id)}
                    newRowText={t(
                      'settingsModule.paymentsSettings.tipSettings.addContributor',
                    )}
                    onNewRow={() => addRolePool('rolePoolContributors')}
                    onRowDelete={(id: number | string) =>
                      deleteRolePool('rolePoolContributors', id as number)
                    }
                    noDataText={t(
                      'settingsModule.paymentsSettings.tipSettings.emptyContributorsPool',
                    )}
                    customErrorText={
                      Object.keys(data.rolePoolContributors || {}).length ===
                        0 &&
                      Object.keys(data.rolePoolRecipients || {}).length > 0
                        ? t(
                            'settingsModule.paymentsSettings.tipSettings.noContributorsError',
                          )
                        : undefined
                    }
                  />

                  <Divider />

                  <TipRolePoolTable
                    validatePercentages
                    title={t(
                      'settingsModule.paymentsSettings.tipSettings.rolesReceivingFromPool',
                    )}
                    firstColumnTitle={t(
                      'settingsModule.paymentsSettings.tipSettings.recipient',
                    )}
                    secondColumnTitle={t(
                      'settingsModule.paymentsSettings.tipSettings.percentageReceived',
                    )}
                    hasZeroText={t(
                      'settingsModule.paymentsSettings.tipSettings.percentageReceivedRequired',
                    )}
                    data={rolePoolRecipientsData}
                    updateValue={(
                      newValue: Partial<ITipRolePool>,
                      id: number | string,
                    ) => updateValue('rolePoolRecipients', newValue, id)}
                    newRowText={t(
                      'settingsModule.paymentsSettings.tipSettings.addRecipients',
                    )}
                    onNewRow={() => addRolePool('rolePoolRecipients')}
                    onRowDelete={(id: number | string) =>
                      deleteRolePool('rolePoolRecipients', id as number)
                    }
                    noDataText={t(
                      'settingsModule.paymentsSettings.tipSettings.emptyRecipientsPool',
                    )}
                    customErrorText={
                      Object.keys(data?.rolePoolContributors || {}).length >
                        0 &&
                      Object.keys(data?.rolePoolRecipients || {}).length === 0
                        ? t(
                            'settingsModule.paymentsSettings.tipSettings.noRecipientsError',
                          )
                        : undefined
                    }
                  />
                </>
              )}
              {data?.tipDistribution === TipDistribution.SHARING &&
                data.shareBy && (
                  <>
                    <Divider />
                    <DetailsSubtitle noMargin>
                      {t(
                        'settingsModule.paymentsSettings.tipSettings.sharingRules',
                      )}
                    </DetailsSubtitle>

                    <Grid
                      rowGap={20}
                      columnGap={20}
                      csx={{ alignItems: 'flex-start' }}>
                      <Grid.Item
                        {...gridItemSizing}
                        csx={displayFlexEndColumnStyles}>
                        <Dropdown
                          label={t(
                            'settingsModule.paymentsSettings.tipSettings.shareBy',
                          )}
                          data={[
                            [
                              {
                                label: t(
                                  'settingsModule.paymentsSettings.tipSettings.role',
                                ),
                                value: 0,
                              },
                            ],
                          ]}
                          value={tipShareByToValue[data.shareBy]}
                          onChange={value => {
                            updateValue('shareBy', valueToTipShareBy[value]);
                          }}
                        />
                      </Grid.Item>
                    </Grid>

                    <TipSharingRulesTable
                      data={sharingRulesData}
                      onNewRow={addSharingRule}
                      onRowDelete={id => deleteSharingRule(id)}
                      onRowEdit={(id: number) => setSelectedSharingRule(id)}
                    />
                  </>
                )}
              {data?.tipDistribution !== TipDistribution.INDIVIDUAL &&
                data?.splitTipInterval && (
                  <>
                    <Divider />
                    <Box>
                      <DetailsSubtitle noMargin>
                        {t(
                          'settingsModule.paymentsSettings.tipSettings.splittingRules',
                        )}
                      </DetailsSubtitle>
                      <Grid
                        rowGap={20}
                        columnGap={20}
                        csx={{ alignItems: 'flex-start' }}>
                        <Grid.Item
                          {...gridItemSizing}
                          csx={displayFlexEndColumnStyles}>
                          <Dropdown
                            label={t(
                              'settingsModule.paymentsSettings.tipSettings.interval',
                            )}
                            data={[
                              [
                                {
                                  label: t(
                                    'settingsModule.paymentsSettings.tipSettings.shift',
                                  ),
                                  value: 0,
                                },
                                {
                                  label: t(
                                    'settingsModule.paymentsSettings.tipSettings.day',
                                  ),
                                  value: 1,
                                },
                              ],
                            ]}
                            value={
                              splitTipIntervalToValue[
                                data?.splitTipInterval || 0
                              ]
                            }
                            onChange={value => {
                              updateValue(
                                'splitTipInterval',
                                valueToSplitTipInterval[value],
                              );
                            }}
                            placeholder=""
                          />
                        </Grid.Item>
                      </Grid>
                    </Box>
                  </>
                )}
            </Box>
          </AccordionSection>
          <AccordionSection
            title={t(
              'settingsModule.paymentsSettings.serviceChargeSettings.title',
            )}>
            <ServiceCharge
              data={serviceChargeArray}
              navigateToServiceCharge={id => setServiceChargeId(id)}
            />
          </AccordionSection>
        </Accordion>
      )}
    </>
  );
};

export default Payments;
