import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Typography from '@app/components/common/Typography';
import { displayFlexRowStyles } from '@app/theme/commonStyles';
import { IMenuStore, IReportsStore } from '@westondev/tableturn-core';
import React from 'react';
import RenderComboErrorBody from './RenderComboErrorBody';
import RenderItemErrorBody from './RenderItemErrorBody';
import RenderReportErrorBody from './RenderReportErrorBody';
import RenderSpecialErrorBody from './RenderSpecialErrorBody';
import { redDotStyles } from './styles';

// eslint-disable-next-line react-refresh/only-export-components
export const getBucketBody = (
  bucketName: keyof IMenuStore | keyof IReportsStore | null,
) => {
  if (!bucketName) return <></>;
  switch (bucketName) {
    case 'combos':
      return <RenderComboErrorBody />;
    case 'specials':
      return <RenderSpecialErrorBody />;
    case 'itemComboSalesReports':
    case 'salesOverviewReports':
    case 'endOfShiftReports':
    case 'creditCardBatchReports':
    case 'employeeSalesAndTipsReports':
    case 'voidReasonsReports':
    case 'inventoryOnHandReports':
      return <RenderReportErrorBody />;
    default:
      return <RenderItemErrorBody />;
  }
};

export const VCenteredView = ({ children }: { children: React.ReactNode }) => (
  <Box csx={displayFlexRowStyles}>{children}</Box>
);

export const HorizontalLine = () => <Divider />;

export const SectionText = ({ children }: { children: string }) => (
  <Divider label={children} />
);

export const SubSectionText = ({ children }: { children: string }) => {
  return <Typography fontWeight="medium">{children}</Typography>;
};

export const ErrorRow = ({ children }: { children: React.ReactNode }) => (
  <VCenteredView>
    <RedDot />
    <Box>{children}</Box>
  </VCenteredView>
);

export const RedDot = () => <Box csx={redDotStyles} />;
