import { withTranslation } from 'react-i18next';

import sectionHOC from '@app/hocs/sectionHOC';
import ItemReportingGroupsSubsection from './ItemReportingGroupsSubsection';
import { IItemReportingGroupsSubsection } from './types';

export default withTranslation()(
  sectionHOC<IItemReportingGroupsSubsection>(
    ItemReportingGroupsSubsection,
    'items',
  ),
);
